.menuUsers {
  display: flex;
  justify-content: space-around;
  bottom: 25px;
  right: 25px;
  flex-direction: column;
  position: fixed;
  button {
    background-color: lime;
    border: 1px #00ff55;
    box-shadow: 1px 2px 5px #000;
    height: 50px;
    width: 50px;
    border-radius: 50%;
    margin-top: 10px;
    cursor: pointer;
    svg {
      flex: 1;
      min-width: 20px;
      min-height: 20px;
      max-width: 20px;
      max-height: 20px;
      padding: 0;
      margin: 0;
      margin: auto;
    }
  }
}
.addUsersContainer {
  background-color: rgba(0, 0, 0, 0.2);
  height: 100%;
  width: 100%;
  top: 0;
  position: fixed;
  z-index: 3;
  display: flex;
  .addUsersCardsContainer {
    border-radius: 10px;
    background-color: rgba(255, 255, 255, 0.97);
    min-height: 70vh;
    min-width: 80vw;
    max-width: 80%;
    margin: auto;
    display: flex;
    flex-direction: column;
    .addUsersCardsContainerHeader {
      background-color: #333;
      flex: 3;
      display: flex;
      justify-content: flex-end;
      align-items: center;
      padding: 0.6em;
      border-top-right-radius: 5px;
      border-top-left-radius: 5px;
      display: flex;
      flex-direction: row;
      h4 {
        flex: 25;
        padding: 0;
        margin: 0;
        font-weight: 300;
        color: #fff;
        font-size: 18px;
        // text-align: right;
      }
      .addUsersContainerClose {
        flex: 1;
        margin-right: 10px;
        margin-left: 10px;
        // background-color: red;
        display: flex;
        justify-content: center;
        max-height: 20px;
        button {
          font-size: 25px;
          color: #fff;
          cursor: pointer;
          background-color: transparent;
          border: none;
          margin-top: -13%;
          &:hover {
            color: red;
          }
        }
      }
    }
    .userCards {
      border: 1px solid rgb(245, 245, 245);
      margin: 15px;
      flex: 15;
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      overflow-y: scroll;
      max-height: 60vh;
      padding-top: 20px;
      .userCard {
        margin: 2%;
        // background-color: rgba(0, 0, 0, 0.1);
        border: 1px solid #aaa;
        border-radius: 20px;
        min-height: 300px;
        max-height: 300px;
        display: flex;
        margin-top: 20px;
        flex-direction: column;
        transition: 0.3s;

        &:hover {
          transition: 0.2s;
          box-shadow: 0px 20px 15px -8px rgba(160, 160, 160, 0.6);
          -webkit-transform: translateY(-50%);
          transform: translateY(-1.8%);
          /* opacity: 1; */
          -webkit-transition-delay: 0.1s;
          transition-delay: 0.1s;
          background: (10, 9, 9, 0.75);
          // border:3px solid black;
          /* color:white */
        }
        .cancelCardContainer {
          // position:absolute;
          // background: red;
          width: 320px;
          // right:0;
          margin-bottom: -40px;
          display: flex;
          justify-content: flex-end;
          button {
            margin-right: 12px;
            height: 30px;
            width: 30px;
            font-size: 19px;
            background-color: transparent;
            border: none;
            cursor: pointer;
            transition: 0.3s;
            &:hover {
              transition: 0.3s;
              color: red;
              font-weight: 400;
            }
          }
        }
        .userCardForm {
          margin: auto;
          // background-color: red;
          display: flex;
          flex-direction: column;
          align-items: center;
          input {
            display: block;
            height: 40px;
            width: 95%;
            font-size: 15px;
            border-style: none;
            border-bottom-color: #555;
            border-bottom-style: solid;
            border-bottom-width: thin;
            text-align: center;
            transition: 0.3s;
            margin-bottom: 5px;
            background: transparent;
            &:focus {
              outline: none;
              -webkit-transition: all 0.3s ease-in-out;
              -moz-transition: all 0.3s ease-in-out;
              -ms-transition: all 0.3s ease-in-out;
              -o-transition: all 0.3s ease-in-out;
              border-bottom-color: #00ff21;
              border-bottom-style: solid;
              border-bottom-width: thin;
              box-shadow: 0px 5px 5px -5.1px #00dd21;
            }
          }
          .userTypeOptions {
            padding-top: 10px;
            padding-bottom: 10px;
          }
        }
      }
    }
    .addUserMenu {
      flex: 0.8;
      display: flex;
      justify-content: flex-end;
      align-content: center;
      min-height: 50px;
      button {
        border: none;
        background: #00ff21;
        border-radius: 25px;
        width: 170px;
        max-height: 60%;
        max-height: 40px;
        margin-right: 1%;
        cursor: pointer;
      }
    }
  }
}
.UniversalHeading {
  width: 88%;
  margin: auto;
  font-weight: 100;
  font-size: 40px;
}
#heading {
  text-indent: 20px;
  margin: auto;
  // margin-top: 20px;
  margin-bottom: 10px;
  width: 85%;
  display: flex;
  font-weight: 100;
  // border-radius: 30px;
  // background-color: rgba(42, 42, 42, 0.05);
  // box-shadow: 0 10px 10px -10px rgba(42, 42, 42, 0.05);
  .searchContainer {
    align-self: flex-end;
    justify-self: flex-end;
    right: 0;
    width: 83%;
    display: flex;
    justify-content: flex-end;
    height: 25px;
    .groupby{
      font-size: 18px;
      margin-top: 0.5%;
      h1{
        padding:0;
        margin:0;
      }
    }
    .dropdown {
      flex: 1;
      max-width: 20%;
      &:hover {
        .dropdown-content {
          cursor: default;
          display: block;
        }
      }
      .dropbtn {
        font-size: 16px;
        border: none;
        background-color: #fff;
        font-family: inherit;
        h1 {
          margin: auto;
          font-size: 18px;
          font-weight: 400;
        }
      }
      .dropdown-content {
        display: none;
        position: absolute;
        // background-color: #f1f1f1;

        min-width: 160px;
        box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
        z-index: 1;

        #Customers {
          color: black;
          text-decoration: none;
          display: flex;
          font-size: 18px;
          min-width: 100%;
          justify-content: flex-start;
          padding: 10px 0px;
        }

        #Customers:hover {
          background-color: #ddd;
        }
      }
    }
    .filterUsers {
      /* Size & position */
      position: relative;
      width: 200px;
      margin: 0 auto;

      /* Styles */
      background: #fff;
      border-radius: 5px;
      box-shadow: 0 1px 0 rgba(0, 0, 0, 0.2);
      cursor: pointer;
      color: black;
      transition: all 0.3s ease-out;
      border: none;
      margin-right: 5%;
      /* Font settings */
    }
    .filterUsers:after {
      /* Little arrow */
      content: "";
      width: 0;
      height: 0;
      position: absolute;
      top: 50%;
      right: 15px;
      margin-top: -3px;
      border-width: 6px 6px 0 6px;
      border-style: solid;
      border-color: #4cbeff transparent;
    }
    input {
      // flex:5;
      width: 120px;
      margin-top: 1px;
      border: none;
      font-size: 18px;
      // background:red;
      margin-top: 5px;
      font-family: inherit;
      color: #999;
    }
    .searchIcon {
      // flex:1;
      max-height: 25px;
      svg {
        height: 80%;
        margin-bottom: 5px;
        padding: 0;
      }
    }
  }
}
#headingforsub {
  text-indent: 20px;
  margin: auto;
  // margin-top: 20px;
  margin-bottom: 10px;
  width: 85%;
  display: flex;
  font-weight: 100;
  font-size: 24px;
  color: #666;
  border-radius: 30px;
  background-color: rgba(42, 42, 42, 0.05);
  box-shadow: 0 10px 8px -7px rgba(42, 42, 42, 0.05);
  padding: 3px;

  cursor: pointer;

  .headingButton {
    // background: red;
    // right:0;
    margin-right: 10px;
    display: flex;
    flex: 1;
    justify-content: flex-end;
    align-content: center;
    align-items: center;
  }
}
.divHeadings {
  display: flex;
  margin: auto;
  align-items: center;
  align-content: center;
  width: 80%;
  font-size: 20px;
  font-weight: 100;
  .head1 {
    align-content: flex-start;
    margin-left: 11%;
    flex: 1;
  }
  .head2 {
    align-content: flex-start;
    flex: 1;
  }
  .head3 {
    align-content: flex-start;
    flex: 1;
  }
  .head4 {
    align-content: flex-start;
    flex: 1;
  }
}

#heading button {
  font-size: 15px;
}

#line {
  width: 83%;
}

#heading .addButton {
  width: 200px;
  height: 40px;
  float: right;
  margin-right: 100px;
  cursor: pointer;
}

.structureContainer {
  height: auto;
  min-height: 100px;
  width: auto;
  max-width: 88%;
  margin: auto;
  padding: 20px;
  padding-top: 0px;
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  overflow: hidden;
  justify-content: center;
  section {
    align-self: center;
    justify-self: flex-start;
    // margin-top: -20%;
    // max-height:50px;
    // background-color: red;
    h4 {
      padding: 0;
      margin: 0;
    }
  }
}

.pageContainer .addButton {
  background-color: lime;
  box-shadow: 1px 2px 5px #000;
  border: 0.5px solid lime;
  position: fixed;
  font-size: 50px;
  height: 60px;
  width: 60px;
  border-radius: 50%;
  bottom: 25px;
  right: 25px;
  cursor: pointer;
  img {
    margin: auto;
    flex: 1;
    width: 40px;
    height: 40px;
  }
}

#AddUserForm {
  background-color: rgba(42, 42, 42, 0.8);
  width: 100%;
  height: 100%;
  z-index: 10;
  position: fixed;
  display: grid;
  place-items: center;
  align-items: center;
  transition: 0.3s;
  overflow-y: hidden;
  top: 0;
  overflow-y: scroll;
}

#AddUserForm form {
  height: 350px;
  width: 500px;
  background-color: #fff;
  margin-top: -100px;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  place-items: center;
  border-radius: 5px;
  z-index: 10;
  p {
    background-color: #333;
    margin: 0px;
    color: white;
    // text-align: right;
    // padding: 0.8em;
    height: 40px;
    margin-top: -8px;
    width: 95%;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding-right: 5%;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
  }
}
.userTypeOptions {
  div {
    color: black;
    text-align: left;
  }
}

#AddUserForm form input {
  display: block;
  height: 40px;
  width: 400px;
  font-size: 18px;

  border-style: none;
  border-bottom-color: #555;
  border-bottom-style: solid;
  border-bottom-width: thin;
  text-align: center;
  transition: 0.3s;
}

#AddUserForm form .AddUserSubmit {
  border-radius: 25px;
  border-style: solid;
  border-width: 0px;
  background-color: transparent;
  transition: 0.3s;
  background-color: #00dd21;
  height: 50px;
  width: 200px;
  cursor: pointer;
  transition: 0.3s;
}
#AddUserForm form .AddUserSubmit:hover {
  background-color: #00dd21;
  transition: 0.3s;
  box-shadow: 1px 2px 5px #000;
  border-width: 0px;
  width: 300px;
  font-size: 15px;
  transition: 0.3s;
}

#AddUserForm form .cancelButton {
  border-radius: 50%;
  width: 25px;
  height: 25px;
  margin-left: 10px;
  color: #00dd21;
  background-color: #ededed;
  border: none;
  cursor: pointer;
  font-size: 15px;
  font-weight: 800;
  transition: 0.3s;

  &:hover {
    transition: 0.3s;
    color: red;
  }
}

#AddUserForm form input:focus {
  outline: none;
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -ms-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  border-bottom-color: #00ff21;
  border-bottom-style: solid;
  border-bottom-width: thin;
  box-shadow: 0px 5px 5px -3px #00dd21;
}

#success {
  width: 200px;
  height: 200px;
}

.byCustomer {
}
