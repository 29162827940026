* {
	animation: fadeIn 0.1s;
}

*:focus {
	outline: none !important;
}

@keyframes fadeIn {
	from {
		opacity: 0;
	}
	to {
		opacity: 1;
	}
}
#root {
	width: 100%;
	height: 100%;
}
html,
body {
	margin: 0;
	font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans",
		"Helvetica Neue", sans-serif;
	min-width: 100%;
	min-height: 100%;
	background-color: white;
	display: flex;
	justify-content: center;
	bottom: 0;
	overflow: visible;
	// scrollbar-arrow-color: green;

	// ::-webkit-scrollbar {
	//   max-width: 7px;
	// }

	// ::-webkit-scrollbar-track {
	//   -webkit-box-shadow: 0 0 3px rgba(0, 0, 0, 0.2);
	//   // background: rgba(0, 0, 0, 0.1);
	//   border-radius: 20px;
	// }

	// ::-webkit-scrollbar-thumb {
	//   border-radius: 20px;
	//   background-color: rgba(10, 10, 10, 0.5);
	//   // background: rgba(0, 0, 0, 0.7);
	//   // -webkit-box-shadow: inset 0 0 6px 2px rgba(0, 200, 0, 0.8);
	// }

	.pageContainer {
		margin: 0;
		bottom: 0;
		min-height: 100%;
		margin: auto;
		// background-color: red;
		// display:flex;
	}
}
.addButton {
	display: flex;
	max-width: 50px;
	max-height: 50px;
	min-width: 50px;
	min-height: 50px;
	cursor: pointer;
	svg {
		flex: 1;
		min-width: 20px;
		min-height: 20px;
		max-width: 20px;
		max-height: 20px;
		padding: 0;
		margin: 0;
		margin: auto;
	}
}

.fade-enter {
	opacity: 0;
	transform: scale(0.9);
}
.fade-enter-active {
	opacity: 1;
	transform: translateX(0);
	transition: opacity 200ms, transform 200ms;
}
.fade-exit {
	opacity: 1;
}
.fade-exit-active {
	opacity: 0;
	transform: scale(1);
	transition: opacity 200ms, transform 200ms;
}

.fade-enter-done {
	// height: auto;
	margin: 0;
	padding: 0;
}

.popUpWindow {
	background-color: rgba(42, 42, 42, 0.5);
	width: 100%;
	height: 100%;
	z-index: 10;
	position: fixed;
	display: flex;
	justify-content: space-around;
	align-items: center;
	transition: 0.3s;
	top: 0;
	box-shadow: 0 0 50px 50px rgba(42, 42, 42, 0.5);
}
