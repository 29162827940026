.exportMenuButtons {
	bottom: 0;
	right: 0;
	max-height: 40px;
	width: auto;
	background-color: red;
	position: fixed;
	z-index: 10;
	height: 200px;
	width: 80px;
	background-color: transparent;
	display: flex;
	flex-direction: column;
	justify-content: flex-end;
	align-items: center;
	button {
		cursor: pointer;
		border-radius: 50%;
		border: none;
		flex: 1;
		max-width: 40px;
		min-width: 40px;
		min-height: 40px;
		max-height: 40px;
		margin-bottom: 10%;
		background-color: #3f3;
		svg {
			height: 20px;
			margin: auto;
		}
	}
}

#PreviewContainer {
	width: 88%;
	height: 100%;
	display: flex;
	flex-direction: column;
	margin: auto;

	#showResourceForm {
		background-color: rgba(42, 42, 42, 0.8);
		max-width: 100%;
		max-height: 100%;
		min-width: 100%;
		min-height: 100%;
		z-index: 10;
		position: fixed;
		display: grid;
		place-items: center;
		align-items: center;
		transition: 0.3s;
		top: 0;
		left: 0;
	}

	#showResourceForm form {
		height: 700px;
		width: 95%;
		background-color: #fff;
		// margin-top: -100px;
		display: flex;
		flex-direction: column;
		justify-content: space-around;
		place-items: center;
		border-radius: 5px;
		z-index: 10;
	}

	#showResourceForm form input {
		display: block;
		height: 40px;
		width: 400px;
		font-size: 18px;

		border-style: none;
		border-bottom-color: #555;
		border-bottom-style: solid;
		border-bottom-width: thin;
		text-align: center;
		transition: 0.3s;
	}

	#showResourceForm form .AddUserSubmit {
		border-radius: 25px;
		border-style: solid;
		border-width: 0px;
		background-color: transparent;
		transition: 0.3s;
		background-color: #00dd21;
		height: 50px;
		width: 200px;
		transition: 0.3s;
	}
	#showResourceForm form .AddUserSubmit:hover {
		background-color: #00dd21;
		transition: 0.3s;
		box-shadow: 1px 2px 5px #000;
		border-width: 0px;
		width: 300px;
		font-size: 15px;
		transition: 0.3s;
	}

	#showResourceForm form .cancelButton {
		border-radius: 50%;
		width: 25px;
		height: 25px;
		margin-left: 10px;
		color: #00dd21;
		background-color: #ededed;
		border: none;
		cursor: pointer;
		font-size: 15px;
		font-weight: 800;
		transition: 0.3s;

		&:hover {
			transition: 0.3s;
			color: red;
		}
	}

	#showResourceForm form input:focus {
		outline: none;
		-webkit-transition: all 0.3s ease-in-out;
		-moz-transition: all 0.3s ease-in-out;
		-ms-transition: all 0.3s ease-in-out;
		-o-transition: all 0.3s ease-in-out;
		border-bottom-color: #00ff21;
		border-bottom-style: solid;
		border-bottom-width: thin;
		box-shadow: 0px 5px 5px -3px #00dd21;
	}

	#success {
		width: 200px;
		height: 200px;
	}
	#PreviewHeading {
		flex: 1;
		margin: 0;
		max-height: 65px;
		h1 {
			font-weight: 100;
			font-size: 26px;
			margin: 0;
			margin-top: 1%;
		}
	}
	.PreviewCustomer {
		// text-indent: 20px;
		// margin: auto;
		// // margin-top: 20px;
		// margin-bottom: 10px;
		width: 100%;
		// background-color: red;
		// display: flex;
		// font-weight: 100;
		// font-size: 24px;
		// color: #666;
		// border-radius: 30px;
		// background-color: rgba(42, 42, 42, 0.05);
		// box-shadow: 0 10px 8px -7px rgba(42, 42, 42, 0.05);
		padding: 0;
		margin: 0;

		cursor: pointer;
		// background-color: red;
		#headingforsub {
			text-indent: 20px;
			// margin: auto;
			margin-top: 0;
			// margin-bottom: 10px;
			width: 100%;
			display: flex;
			// background-color: red;
			font-weight: 100;
			font-size: 24px;
			color: #666;
			border-radius: 30px;
			background-color: rgba(42, 42, 42, 0.05);
			box-shadow: 0 10px 8px -7px rgba(42, 42, 42, 0.05);
			padding: 3px;
			padding-bottom: 0;
			.headingButton {
				//   background: red;
				// right:0;
				margin-right: 10px;
				display: flex;
				flex: 1;
				justify-content: flex-end;
				align-content: center;
				align-items: center;
			}
		}
	}
	section {
		flex: 9;
		display: flex;
		flex-direction: column;
		.PreviewCustomer {
			display: flex;
			// flex: 1;
			// min-height: auto;
			flex-direction: column;
			.childrenHeaders {
				// background-color: red;
				margin-bottom: 20px;
				border-bottom: 1px solid #eee;
				display: flex;
				flex: 1;
				width: 100%;
				.logo {
					flex: 1;
					display: flex;
					border-radius: 50%;
					border: none;
					img {
						margin: auto;
						min-height: 70px;
						max-height: 70px;
						max-width: 70px;
						min-width: 70px;
						border-radius: 50%;
						border: 3px solid #fff;
					}
				}
				.PreviewCustomer {
					flex: 9;
					display: flex;
					flex-direction: column;
					.PreviewCustomerName {
						display: flex;
						flex: 1;
						align-items: flex-end;
						font-size: 22px;
						font-weight: 500;
					}
					.PreviewCustomerHours {
						display: flex;
						flex: 1;
						label {
							flex: 1;
						}
					}
				}
			}
		}
		.PreviewContent {
			.PreviewDates {
				display: flex;
				font-weight: 100;
				font-size: 18px;
				justify-content: center;
				padding-bottom: 10px;
				padding-top: 10px;
			}
			.childrenHeaders {
				// background-color: red;
				margin-bottom: 20px;
				border-bottom: 1px solid #eee;
				display: flex;
				flex: 1;
				width: 100%;
				.logo {
					flex: 1;
					display: flex;
					border-radius: 50%;
					border: none;
					img {
						margin: auto;
						min-height: 70px;
						max-height: 70px;
						max-width: 70px;
						min-width: 70px;
						border-radius: 50%;
						border: 3px solid #fff;
					}
				}
				.PreviewCustomer {
					flex: 9;
					display: flex;
					flex-direction: column;
					.PreviewCustomerName {
						display: flex;
						flex: 1;
						align-items: flex-end;
						font-size: 22px;
						font-weight: 500;
					}
					.PreviewCustomerHours {
						display: flex;
						flex: 1;
						label {
							flex: 1;
						}
					}
				}
			}
			.PreviewContentHeading {
				// background-color: blue;
				display: flex;
				.viewMenuContainer {
					flex: 1;
					display: flex;
					// justify-content: center;
					// align-content: center;
					// align-items: center;
					// background-color: red;
					font-size: 19px;
					// font-weight: 100;
					// max-height: 200px;
					// min-height: 200px;
					// min-width: 100px;
					.Previewburgermenu {
						// position: absolute;
						right: 0;
						// width: 11%;
						// display: flex;
						min-height: 50px;
						// margin-top: 0.5%;
						// background-color: red;
						.PreviewburgerIcon {
							button {
								cursor: pointer;
								border-radius: 50%;
								border: none;
								max-width: 35px;
								min-width: 35px;
								min-height: 35px;
								max-height: 35px;
								background-color: #3f3;
								svg {
									margin: auto;
									height: 20px;
									width: 20px;
								}
							}
						}
					}
					.menutoggles {
						.viewMenu {
							display: flex;
							align-items: center;
							min-height: 200px;
							flex-direction: column;
							position: absolute;
							right: 3%;
							// margin-top: 6.1%;
							button {
								cursor: pointer;

								border-radius: 50%;
								border: none;
								flex: 1;
								max-width: 35px;
								min-width: 35px;
								min-height: 35px;
								max-height: 35px;
								margin-bottom: 20%;
								background-color: #3f3;
							}
						}
						.enableMenu{
							display: flex;
							align-items: center;
							min-height: 200px;
							flex-direction: column;
							position: absolute;
							right: 0.5%;
							// margin-top: 6.1%;
							button {
								cursor: pointer;

								border-radius: 50%;
								border: none;
								flex: 1;
								max-width: 35px;
								min-width: 35px;
								min-height: 35px;
								max-height: 35px;
								margin-bottom: 20%;
								background-color: transparent;
								svg{
									width: 25px;
									height: 25px;
								}
							}
						}
					}
				}
				// button {
				// 	position: absolute;
				// 	margin-top: -0.1%;
				// 	margin-left: -5%;
				// 	background-color: transparent;
				// 	// background-color: red;
				// 	border: none;

				// 	svg {
				// 		width: 25px;
				// 		height: 25px;
				// 	}
				// }
				.headname {
					flex: 200;

					#headingforsubreport {
						margin: auto;
						margin-top: 0;
						margin-bottom: 20px;
						width: 30%;
						display: flex;
						font-weight: 400;
						font-size: 20px;
						color: #111;
						border-radius: 30px;
						padding: 3px;
						padding-bottom: 10px;
						button {
							// position: absolute;
							margin-top: -0.4%;
							// margin-left: -7%;
							background-color: transparent;
							// background-color: red;
							border: none;
							flex: 1;
							display: flex;
							justify-content: flex-end;
							svg {
								width: 25px;
								height: 25px;
							}
						}
						.headingButton {
							// background-color: red;

							flex: 1;
							display: flex;
							// flex: 0.5;
							justify-content: flex-start;
							align-content: center;
							align-items: center;
						}
						p {
							flex: 3;
							margin: 0;
							padding: 0;
							text-align: center;
							// background: blue;
						}
					}
				}
			}

			.chart {
				min-width: 500px;
				// max-width: 950px;
				margin: auto;
				.apexcharts-legend-text {
					// font-size: 100px;
					// background-color: red;
				}
			}
			.ContentContainer {
				// background-color: rgba(230, 230, 230, 0.2);
				border-radius: 20px;
				display: flex;
				flex-direction: column;
				flex: 1;
				margin: auto;
				margin-bottom: 5%;
				width: 100%;
				.subContentContainer {
					display: flex;
					flex-direction: row;
					.chartColumn {
						// background-color: red;
						flex: 1;
						min-width: 83vw;
						min-width: 83vw;
					}
					button {
						margin-top: 0;
						// position: absolute;
						margin-right: 2%;
						// margin-top: 5px;
						//  background-color: red;
						background-color: transparent;
						border: none;
						display: flex;
						justify-content: start;
						svg {
							width: 25px;
							height: 25px;
						}
					}
				}
			}
			// .PreviewActivity {
			// 	background-color: rgba(230, 230, 230, 0.2);
			// 	border-radius: 20px;
			// 	display: flex;
			// 	flex-direction: column;
			// 	flex: 1;
			// 	margin-bottom: 5%;
			// }
			// .PreviewUser {
			// 	background-color: rgba(230, 230, 230, 0.2);
			// 	border-radius: 20px;
			// 	display: flex;
			// 	flex-direction: column;
			// 	flex: 1;
			// 	margin-bottom: 5%;
			// }
			.EntriesReport,
			.ServiceReportTable,
			.ActivityeReportTable,
			.UserReportTable {
				padding: 0;
				padding-bottom: 2%;

				display: flex;
				flex-direction: column;
				overflow: hidden;
				flex: 1;
				.ColumnHeadings {
					display: flex;
					width: 100%;
					margin-left: 1px;
					label {
						color: #333;
						flex: 1;
						font-size: 19px;
						font-weight: 350;
						display: flex;
						justify-content: center;
						border-bottom: 2px solid #ddd;
					}
					.headingdate {
						max-width: 8.1%;
					}
				}
				#student {
					flex: 1;
					color: black;
					table {
						min-width: 100%;
						tbody {
							display: flex;
							flex-direction: column;
							tr {
								display: flex;
								flex: 1;
								td {
									flex: 1;
									border-bottom: thin solid #ddd;
									display: flex;
									justify-content: center;
									text-align: center;
									font-weight: 200;
								}
								.entrydate {
									max-width: 8%;
								}
								.description {
									max-height: 300px;
									//   max-width: 100px;
									word-break: break-all;
									overflow-y: hidden;
									//   text-overflow: ellipsis;
								}
							}
						}
					}
				}
			}
			.PreviewEntries {
				background-color: rgba(230, 230, 230, 0.2);
				border-radius: 20px;
				display: flex;
				flex-direction: column;
				flex: 1;
				margin-bottom: 5%;

				#chart {
					max-height: 70%;
				}
			}
		}
	}
}
