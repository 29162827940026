.statscards {
    height: 100%;
    width: 88%;
    margin: auto;
    .statsContainer {
        // background-color: red;
        // height: 10vh;
        cursor: default;
        margin: 10px;
        margin-bottom: 3%;
        display: flex;
        transition: 0.5s;
        align-items: center;
        &:hover {
            transition: 0.2s;
            background-color: rgba(160, 160, 160, 0.2);
            box-shadow: 0px 8px 20px -20px #333;
            -webkit-transform: translateY(-50%);
            transform: translateY(-3%);
            -webkit-transition-delay: 0.1s;
            transition-delay: 0.1s;
            cursor: pointer;
        }
        .imagecomp {
            flex: 1;
            display: flex;
            justify-content: center;
            img {
                width: 35px;
                height: 35px;
                margin: auto;
                border-radius: 50%;
                cursor: pointer;
            }
            .MuiAvatar-colorDefault {
                // height: 30px;
                // width: 30px;
                p {
                    // font-size: 13px;
                    font-weight: bold;
                }
            }
        }
        .statscomp {
            flex: 9;
            display: flex;
            flex-direction: column;
            color: #555;
            .name {
                flex: 1;
                font-weight: 500;
                padding-bottom: 5px;
                color: #000;
                display: flex;
                label {
                    flex: 4;
                    cursor: pointer;
                }
                .filterperiod {
                    // font-style: italic;
                    color: #333;
                    font-weight: 350;
                    padding-left: 1%;
                    flex: 6;
                    // background: red;
                    text-align: right;
                }
                p {
                    flex: 1.5;
                    text-align: center;
                    padding: 0;
                    margin: 0;
                    font-weight: 450;
                }
                .progress {
                    // width:80%;
                    display: flex;
                    font-weight: 100;
                    // padding-left: 20px;
                    flex: 2.3;
                    label {
                        text-align: right;
                    }
                    .dot {
                        margin: auto;
                        margin-left: 10px;
                        width: 12px;
                        height: 12px;
                        // background-color: red;
                        border-radius: 50%;
                    }
                }
            }
            .statistics {
                flex: 9;
                display: flex;
                flex-direction: column;
                .statContainer {
                    flex: 1;
                    display: flex;
                    flex-direction: row;
                    .cont {
                        display: flex;
                        flex-direction: column;
                        flex: 1;
                        label {
                            font-size: 12px;
                            display: flex;
                            flex-direction: row;
                            padding-bottom: 2%;
                            p {
                                margin: 0;
                                padding: 0;
                                margin-right: 5%;
                            }
                        }
                        .stathead {
                            font-size: 15px;
                            color: #333;
                            padding-bottom: 0;
                        }
                    }
                }
            }
        }
    }
}
