.TimeSheetContainer {
    display: flex;
    flex: 1;
    flex-direction: column;
    justify-content: space-evenly;
    .TimeSheetContainerLeftandRight {
        display: flex;
        flex: 1;
        flex-direction: row;
        justify-content: space-evenly;
    }
    .mainmenu {
        flex: 1;
        display: flex;
        justify-content: flex-end;
        align-items: center;
	right:1%;
    flex-direction: column;
        button {
            background-color: lime;
            height: 50px;
            width: 50px;
            margin-bottom: 10px;
            border: none;
            border-radius: 50px;
            box-shadow: 1px 2px 5px #000;
            svg{
                width: 20px;
                height: 20px;
            }
        }
    }
}
