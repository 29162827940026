.formHolder{
    flex : 1;
    display: flex;
    width: 100%;

    .viewProfileContainer{

        // z-index: 1;
        height: 50%;
        max-width: 80%;
        margin:auto;
        margin-top: 5%;
        flex: 1;
        display: flex;
        flex-direction: row;
        
        .forProfilePicture{
            height: 100%;
            background: #fff;
            max-width: 45%;
            flex:1;
            order:2;
            background-color: transparent;
            display: flex;
            flex-direction: column;

            img{
                max-width: 100%;
                max-height: 70%;
                background-color: black;
                flex: 2;
                order:1;
                object-fit: contain;
            
            }
            .userDescription{
                flex:1;
                background-color: rgba(255, 255, 255, 0.548);
                min-height: 250px;
                order: 2;

                .userDescContainter{
                    margin: auto;
                }
            }
        } 

        form{
            max-height: 650px;
            width: 50%;
            background-color: rgba(230,230,230,.9);
            order: 1;
            flex:3;

            header{
                display: flex;
                width:90%;
                margin:auto;
                border-style:none;
                border-width:0px;
                border-bottom-color: gray;
                border-bottom-style: solid;
                border-bottom-width: thin;
                
                #BackfromProfile{
                    width: 40px;
                    height: 40px;
                    flex: 1;
                    border-style: none;
                    background-color: black;
                    color: white;
                    font-size: 30px;
                    margin:2%;
                    margin-left:0px;
                }

                h2{
                    text-align: right;
                    margin:auto;
                    flex: 5;
                }
            }
            .profileNav{
                display: flex;
                width: 80%;
                // background-color: green;
                margin: auto;

                .buttonContainer{
                    display: flex;
                    // flex-direction: row;
                    flex-wrap: wrap;
                    justify-content: space-evenly;
                   
                    $colors: (
                    b1:black,
                    );
                
                    @each $button, $color in $colors {
                        .#{$button} {
                          --color: #{$color};
                          --hover: #{adjust-hue($color, 0deg)};
                        }
                      }
                      
                      button {  
                        color: var(--color);
                        transition: 1.5s;
                        
                        &:hover,
                        &:focus { 
                          border-color: var(--hover);
                          color: white;
                        }
                      }
                      

                    .b1{
                        flex:1;
                        margin-top: 2px;
                        margin-left: 2%;
                        height: 80px;
                        width: 300px;
                        border-style: none;
                        // border-bottom-style: solid;
                        // border-top-style: solid;
                        // border-bottom-width: thin;
                        // border-top-width: thin;
                        background-color :#aaa;
                        color:black;
                        font-size: 16px;
                        transition: .3s;
                            &:hover, &:focus {
                                box-shadow: inset 0 0 0 4em var(--hover);
                              }
                              
                        
                    }
                }
            }
            
        }
    }
}
