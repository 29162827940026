.register {
  height: 100vh;
  width: 100%;
  min-width: 500px;
  min-height: 200px;
  margin: auto;
}

.block {
  height: 100%;
  width: 60%;
  background: rgb(17, 17, 17);
  display: block;
  text-align: center;
  display: inline-block;
  float: left;

  .slide-container {
    width: 70%;
    margin: auto;
  }

  h3 {
    text-align: center;
  }

  .each-slide > div {
    display: flex;
    align-items: center;
    justify-content: center;
    background-size: cover;
    height: 115vh;
    // margin-top: 25px;
  }
  .each-slide h1 {
    padding: 20px;
    font-size: 20px;
    background: #efefef;
    text-align: center;
  }

  .each-slide span {
    padding: 20px;
    font-size: 20px;
    background: #efefef;
    text-align: center;
  }

  .each-fade {
    display: flex;
  }

  .each-fade .image-container {
    width: 75%;
    overflow: hidden;
  }

  .each-fade .image-container img {
    width: 100%;
  }

  .each-fade h2 {
    width: 25%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0;
    background: #adceed;
  }
}

.register .registrationForm {
  height: 100%;
  width: 40%;
  display: inline-block;
  text-align: center;
  margin: auto;
}

.register .registrationForm form {
  height: 90%;
  width: 90%;
  text-align: left;
  text-indent: 5%;
  display: inline-block;
}

.register .registrationForm form h3 {
  margin-top: 4%;
}
.forForm {
  margin: auto;
  margin-bottom: 17px;
}
.register .registrationForm form input[type="text"] {
  margin-left: 5%;
  width: 80%;
  height: 30px;
  font-size: 16px;
  color: #333;
  border-style: groove;
  border-width: 0px;
  background-color: transparent;
  border-bottom-width: 1px;

  &:focus {
    outline: none;
    -webkit-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    -ms-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    border-bottom-color: #00ff21;
    border-bottom-style: solid;
    border-bottom-width: thin;
    box-shadow: 0px 5px 5px -3px #00dd21;
  }
}
.register .registrationForm form input[type="password"] {
  margin-left: 5%;
  width: 80%;
  height: 30px;
  font-size: 16px;
  color: #333;
  border-style: groove;
  border-width: 0px;
  background-color: transparent;
  border-bottom-width: 1px;
  &:hover {
    box-shadow: 0px 5px 5px -3px #00dd21;
  }
}

.register .registrationForm form input[type="checkbox"] {
  margin-top: 20px;
  margin-left: 5%;
  width: 40px;
}
.styleThem {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
  margin: 50px;
  margin-top: 1px;
  $colors: (
    b1: lime
  );
  @each $button, $color in $colors {
    .#{$button} {
      --color: #{$color};
      --hover: #{adjust-hue($color, 0deg)};
    }
  }
  button {
    color: var(--color);
    transition: 1.5s;
    &:hover,
    &:focus {
      border-color: var(--hover);
      color: white;
    }
  }
  .b1 {
    flex: 1;
    margin-top: 2px;
    margin-left: 2%;
    max-height: 40px;
    min-height: 40px;
    max-width: 120px;
    min-width: 120px;
    border-radius: 10px;
    margin: auto;
    border-style: none;
    background-color: lime;
    color: black;
    font-size: 16px;
    transition: 0.3s;
    &:hover,
    &:focus {
      box-shadow: 0 12px 16px 0 rgba(0, 0, 0, 0.24), 0 17px 50px 0 rgba(0, 0, 0, 0.19);
      cursor: pointer;
    }
  }
}
