.navpreviewContainer {
	height: 80px;
	background: rgba(0, 0, 0, 0.8);
	width: 100%;
	display: flex;
	flex-direction: row;
	.navpreviewLogo {
		flex: 1;
		display: flex;
		justify-content: flex-start;
        align-items: center;
        max-height: 40%;

		img {
            height:100%;
            margin-left:5%;
            margin-top:55px;
            
		}
		#nameBrand {
            margin-left: 10px;
        }
        #bird{
            height:190%;
            margin-right:-2.5%;

        }
	}
	.navpreviewDetails {
        flex: 6;
		display: flex;
        flex-direction: row;
        align-items: center;
        color:#fff;
        font-size: 22px;
		.Customername {
            flex: 7;
            display: flex;
            justify-content: flex-end;
		}
		.navdates {
            display: flex;
            flex: 4;
            justify-items: flex-start;
			.start {
                flex:1;
                display:flex;
                justify-content: flex-end;
            }
            label{
                width:50px;
                // background-color: red;
                display:flex;
                justify-content: center;
            }
            .end{
                flex:1;
                display:flex;
                justify-content: flex-start;
            }
		}
	}
}
