.homeContainer {
    height: 100%;
    width: 100%;
    display: flex;
    .timesheetMenu {
        position: fixed;
        height: auto;
        width: 100px;
        right: 0;
        bottom: 0;
        display: flex;
        // background-color: blue;
        flex-direction: column;
    }
    .homeView {
        flex: 1;
        display: flex;
        justify-content: center;
        // background-color: red;
        .timesheet {
            flex: 1;
            display: flex;
            max-width: 95%;
            .noresourcecustomer {
                position: fixed;
                z-index: 1;
                height: 100%;
                width: 100%;
                background-color: rgba(255,255,255, .5);
                display: flex;
                justify-content: center;
                align-items: center;
                // top:0;
                left: 0;
                .noresourcecustomercont{
                    margin-top: -25%;
                    height: auto;
                    width: 75%;
                    background-color: #fff;
                    box-shadow: 0px 0px 20px -5px rgba(0,0,0, .5);
                    border-radius: 8px;
                    h1{
                        text-align: center;
                        font-size: 22px;
                        padding: 20px;
                    }
                }
            }
        }
        .calendarComponent {
            // background-color: blue;
            flex: 1;
        }
    }
}
