.SidebarContainer {
    background-color: rgba(0, 0, 0, 0.89);
    min-height: 100%;
    max-height: 100%;
    min-width: 300px;
    max-width: 300px;
    display: flex;
    flex-direction: column;
    color: #efefef;
    .sidebarprofile {
        flex: 1;
        .sidebarimage {
            display: flex;
            justify-content: center;
            align-items: center;
            padding-top: 5%;
            padding-bottom: 5%;
            img {
                height: 160px;
                width: 160px;
                border-radius: 50%;
                border: 2px solid rgba(255, 255, 255, 0.98);
            }
        }
        .sidebarusername {
            display: flex;
            flex-direction: column;
            p {
                text-align: center;
                margin: 0;
                padding-top: 5px;
                padding-bottom: 5px;
            }
        }
    }
    .sidebarnav {
        flex: 20;
        ul {
            list-style: none;
            display: flex;
            flex-direction: column;
            list-style-type: none;
            padding: 0;
            li {
                color: #efefef;
                flex: 1;
                display: flex;
                flex-direction: row;
                transition: 0.3s;
                display: flex;
                align-items: center;

                &:hover {
                    transition: 0.2s;
                    color: #0c4;
                }
                .navIcon {
                    flex: 1;
                    height: 100%;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    svg{
                        height: 18px;
                        width: 18px;
                        path{
                            stroke: #efefef;
                        }
                    }
                }
                p {
                    min-height: 100%;
                    flex: 6;
                    display: flex;
                    justify-content: flex-start;
                    align-items: center;
                    font-size: 15px;
                }
            }
        }
    }
    .Poweredby{
        display: flex;
        justify-content: center;
        margin-bottom: 5px;
    }
}

.sideBarButton {
    right: 0;
    position: absolute;
    margin-top: 0.5%;
    height: 35px;
    width: 35px;
    border: none;
    background-color: transparent;
    cursor: pointer;
    svg {
        height: 100%;
        width: 100%;
        color: #0d3;
    }
}

// div[role=navigation]{
//     z-index: 1;
// }
