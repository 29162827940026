#treeWrapper {
  width: 83%;
  min-height: 100vh;
  margin: auto;
  display: flex;
  // align-items: center;
  justify-content: center;
  .treeContainer {
    min-width: 500px;
    min-height: 500px;
    height:auto;
    width:100%;
    // display: flex;
  }
}
