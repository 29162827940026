.assistantContainer{
    display: flex;
    height: auto;
    width: 60%;
    margin: auto;
    margin-bottom: 0;
    margin-top:0;
    top:0;
    flex:1;
    flex-direction: column;
    align-items: center;
    #header{
        margin:10px;
        font-weight: 100;
    }
    #line{
        margin:auto;
        width:97%;

    }
    .topper{
        display: flex;
        flex: 1;
        width: 100%;
        flex-direction: column;
        background: rgba(10, 9, 9, 0.75);
        transition: .3s;

        &:hover{
            transition: .3s;
            box-shadow: 0px 5px 15px 10px #222;
        }
    }
    .top{
        min-height: 350px;
        max-height: 300px;
        display:flex;
        transition: .3s;

        .details{
            flex:1;
            .divDetails{
                width:90%;
                height:90%;
                margin:auto;
                h1{
                    font-weight: 1;
                    border-bottom: 1px solid #777;
                    padding-bottom: 1%;
                }
                h2{
                    margin:0;
                    font-weight: 1;
                    font-size:18px;
                }
            }
        }
        .curruser{
            flex: 1;
            display:flex;
            width:60%;
            max-height: 80%;
            img{
                margin: auto;
                min-width: 60%;
                min-height:90%;
                max-height: 200px;
                max-width: 70%;
                transition: .3s;
                flex:1;

            }
            
        }
    }
    .middleA{
        background: rgba(10, 9, 9, 0.75);
        flex:1;
        display:flex;
        flex-direction: column;
        margin:0;
        margin-top:2%;
        margin-bottom:2%;
        width:100%;
        min-height: 300px;
        transition: .3s;


        &:hover{
            transition: .3s;
            box-shadow: 0px 5px 15px 10px #222;
        }

        .customerdud{
            flex:1;
            width: 100%;
            display: flex;
            min-height: 100%;
            align-items: center;
            flex-direction: row;
            flex-wrap: wrap;
            transition: .3s;
            img{
                margin:1%;
            }
                    
        }
    }
    .bottom{
        // background: rgba(0,0,0,.7);
        // flex:1;
        // flex-direction: column;
        // display:flex;
        // flex-direction: row;
        // margin:0;
        // // min-width:100%;
        // min-height: 300px;

        display: flex;
        flex: 1;
        width: 100%;
        flex-direction: column;
        background: rgba(10, 9, 9, 0.75);
        transition: .3s;

        &:hover{
            box-shadow: 0px 5px 15px 3px #111;
            transition: .3s;

        }
        .userdud{
            flex-direction: row;
            flex: 1;
            margin: auto;
            justify-content: center;
            margin-bottom: 0%;
            margin-top: 0%;
            min-width: 100%;
            display: flex;
            transition: .3s;
            max-height: 245px;

            .cardContainer{
                width: 180px;
                height: 200px;
                margin : 0px;
                margin-bottom : 0px
            }
            .card{
                max-height: 230px;
                margin-bottom : 0px

            }
            .card:hover{
                transition: .3s;
                box-shadow: none;
                -webkit-transform: translateY(0%);
                transform: translateY(0%);
                opacity: 1;
                -webkit-transition-delay: 0.1s;
                transition-delay: 0.1s;
                background: inherit
            }
            
            .card:hover .cardImage img{
                height: 83%;
                width: 83%;
                transition: .3s;
                border-radius:0%;
                opacity: 1;
            }
            
            .card .cardImage img{
                border-radius: 100%
            }
            
            .card:hover:after,
            .card.hover:after {
            -webkit-transform: rotateX(0);
            transform: rotateX(0);
            opacity: 1;
            }
        }
    }
}