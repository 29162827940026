.TimeSheetCalendarContainer {
    display: flex;
    width: 100%;
    .PreviewCalendar {
        flex: 1;
        display: flex;
        flex-direction: column;
        padding-bottom: 10px;
        min-width: 700px;
        .MonthYear {
            flex: 1;
            display: flex;
            justify-content: center;
            align-items: center;
            padding-top: 40px;
            padding-bottom: 40px;
            font-size: 35px;
        }
        .calenderHeaders {
            flex: 1;
            display: flex;
            justify-content: center;
            align-items: center;
            color: #333;
            th {
                flex: 1;
                overflow: hidden;
                text-overflow: ellipsis;
            }
            .week-day {
                font-size: 20px;
            }
        }
        .calenderDays {
            color: #444;
            flex: 9;
            display: flex;
            flex-direction: column;
            tr {
                display: flex;
                flex: 1;
                flex-direction: row;
                td {
                    flex: 1;
                    margin: 1px;
                    height: 100px;
                    border: 1px solid #ddd;
                    display: flex;
                    .calendarNumber { 
                        background-color: #afa;
                        position: relative;
                        height: 25px;
                        width: 25px;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        border-radius: 50%;
                        font-size: 13px;
                        margin: 0.1%;
                        margin-right: -20%;
                        margin-top: 2px;
                        margin-left: 2px;
                        border:none;
                    }
                    .HoursList {
                        flex: 1;
                        display: flex;
                        flex-direction: column;
                        justify-content: center;
                        z-index: 10;
                        label {
                            margin-top: 2%;
                            margin-bottom: 2%;
                            overflow: hidden;
                            text-overflow: ellipsis;
                        }
                    }
                    #left {
                        text-indent: 12%;
                    }
                    #hourslistcalendar {
                        align-items: center;
                    }
                }
                #calendar-day-empty {
                    background-color: #f3f3f3;
                }
            }
        }
    }
}
