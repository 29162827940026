.MonthEntriesContainer {
    height: 100%;
    width: 100%;
    position: fixed;
    z-index: 3;
    top: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.1);
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    .innercontainer {
        height: 80%;
        width: 60vw;
        background-color: #fff;
        box-shadow: 0px 0px 30px -15px rgba(0, 0, 0, 0.8);
        border-radius: 8px;
        display: flex;
        flex-direction: column;

        .closethis {
            height: 40px;
            display: flex;
            justify-content: flex-end;
            button {
                background-color: #fff;
                border-radius: 8px;
                border: none;
                margin-right: 5px;
                svg{
                    font-size: 18px;
                }
            }
        }
        .innerinner {
            overflow-y: scroll;

            .entriescontainer {
                height: auto;
                display: flex;
                flex-direction: column;
                align-items: center;
                margin-bottom: 5px;

                .datediv {
                    height: 30px;
                    width: 97%;
                    margin-top: 5px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    font-weight: 450;
                    font-size: 18px;
                    cursor: pointer;
                }
                .entrydiv {
                    margin-bottom: 10px;
                    width: 97%;
                    background-color: rgba(100, 100, 100, 0.1);
                    border-radius: 8px;
                    .custandtime {
                        display: flex;
                        flex-direction: row;
                        padding-top: 2px;
                        .customerdiv {
                            flex: 1;
                            font-size: 16.5px;
                            font-weight: 450;
                            color: #333;
                            text-indent: 10px;
                        }
                        .timediv {
                            flex: 2;
                            display: flex;
                            justify-content: flex-end;
                            color: #555;
                            font-size: 12px;
                            margin-right: 10px;
                        }
                    }
                    .servicediv {
                        color: #555;
                        font-size: 14px;
                        text-indent: 10px;
                    }
                    .activitydiv {
                        color: #555;
                        font-size: 14px;
                        text-indent: 10px;
                    }
                    .billingdiv {
                        color: #555;
                        font-size: 14px;
                        text-indent: 10px;
                    }
                    .descriptiondiv {
                        color: #222;
                        font-size: 14px;
                        word-break: break-all;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        height: 15px;
                        text-indent: 10px;
                        padding-bottom: 2px;
                    }
                }
            }
        }
    }
}
