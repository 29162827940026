.userListContainer {
    position: fixed;
    display: flex;
    height: 100vh;
    width: 100%;
    background-color: rgba(0, 0, 0, 0.1);
    justify-content: flex-end;
    align-items: flex-end;
    top: 0;
    left: 0;
    z-index: 2;

    section {
        box-shadow: 0px 8px 15px -4px #000;
        margin-right: 2%;
        margin-bottom: 9%;
        border-bottom-right-radius: 10px;
        border-bottom-left-radius: 10px;
        border-top-right-radius: 10px;
        border-top-left-radius: 10px;
        .searchUserContainer {
            border-top-right-radius: 10px;
            border-top-left-radius: 10px;
            height: 40px;
            width: 350px;
            border-bottom: thin solid #ddd;
            background: inherit;
            display: flex;
            align-items: center;
            background-color: #fff;

            input {
                border: none;
                flex: 3;
                margin: 0;
                padding: 0;
                height: 100%;
                text-indent: 10px;
                font-size: 15px;
                border-top-left-radius: 10px;
            }
            svg {
                flex: 1;
                height: 55%;
                background-color: white;
            }
        }
        .userList {
            border-bottom-right-radius: 10px;
            border-bottom-left-radius: 10px;
            cursor: pointer;
            height: 55vh;
            min-width: 350px;
            background-color: #fff;
            overflow: hidden;
            overflow-y: scroll;
            
            .usercardContainer {
                padding-top: 10px;
                padding-bottom: 10px;
                display: flex;
                .indicate {
                    flex: 1;
                    margin: auto;
                    height: 8px;
                    max-width: 8px;
                    border-radius: 50%;
                    margin-left: 3%;
                }
                .imageContainer {
                    flex: 2;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    img {
                        height: 30px;
                        width: 30px;
                        border-radius: 50%;
                    }
                    .MuiAvatar-colorDefault {
                        height: 30px;
                        width: 30px;
                        p {
                            font-size: 13px;
                            font-weight: bold;
                        }
                    }
                }
                .nameContainer {
                    text-indent: 10px;
                    font-size: 17px;
                    font-weight: 100;
                    flex: 8;
                    display: flex;
                    align-items: center;
                    transition: 0.3s;
                }
                &:hover {
                    transition: 0.3s;
                    background-color: #ddd;
                }
            }
            #logged {
                // background-color: rgba(200,200,200, 0.2);
                // border-bottom: 1px solid #eee;
                margin-bottom: 5%;
                box-shadow: 0px 0px 70px -22px #000;
            }
        }
    }
}
