// .resourceForm {
//   width: 100%;
//   height: 100px;
//   margin: auto;
// }
// .chart {
//   min-width: 600px;
//   max-width: 600px;
//   // margin-right: 15%;
//   margin: auto;
//   // min-height: 110%;
//   // display: flex;
//   // align-items: center;
//   // justify-content: center;
//   // background:red
// }
// .reportPreview {
//   position: fixed;
//   z-index: 10;
//   top: 0;
//   display: flex;
//   height: 100%;
//   width: 100%;
//   background-color: rgb(200, 200, 200);
//   .reportPreviewContainer {
//     background-color: #fff;
//     border-bottom-left-radius: 5px;
//     border-bottom-right-radius: 5px;
//     border-top-left-radius: 5px;
//     border-top-right-radius: 5px;

//     margin: auto;
//     width: 95%;
//     height: 87vh;
//     margin-top: 2%;
//     #ReportViewContainer {
//       background-color: #fff;

//       max-width: 100%;
//       min-width: 100%;
//       min-height: 100%;
//       max-height: 100%;
//       overflow: hidden;
//       overflow-y: visible;
//       .ServiceReport {
//         padding: 0;
//         width: 100%;
//         min-height: 100%;
//         max-height: 100%;
//         background-color: #fff;
//         .chart {
//           min-width: 600px;
//           max-width: 600px;
//           margin: auto;
//         }
//       }
//       .ActivityReport {
//         padding: 0;
//         min-height: 120%;
//         background-color: #fff;
//         .chart {
//           min-width: 500px;
//           max-width: 500px;
//           margin: auto;
//         }
//       }
//       .UserReport {
//         padding: 0;
//         max-height: 120%;
//         max-width: 99%;
//         background-color: #fff;
//         .chart {
//           min-width: 100px;
//           max-width: 100px;
//           margin: auto;
//         }
//       }
//       #EntriesReport,
//       #ServiceReportTable,
//       #ActivityeReportTable,
//       #UserReportTable {
//         padding: 0;
//         min-height: 120%;
//         max-height: 470px;
//         display: flex;
//         flex-direction: column;
//         overflow-y: hidden;
//         // overflow-y: scroll;
//         background-color: #fff;

//         .ColumnHeadings {
//           display: flex;
//           position: fixed;
//           width: 98.75%;
//           margin-left: 1px;
//           background-color: #fff;
//           // z-index: 0;
//           label {
//             color: black;
//             flex: 1;
//             font-size: 19px;
//             font-weight: 100;
//             border: 1px solid transparent;
//             border-bottom: 1px solid #777;
//             display: flex;
//             justify-content: center;
//           }
//           .headingdate {
//             max-width: 8.1%;
//           }
//         }
//         #students {
//           flex: 1;
//           color: black;
//           margin-top: 2%;
//           overflow-y: hidden;

//           table {
//             // min-height: 100%;
//             min-width: 100%;
//             overflow-y: hidden;

//             tbody {
//               display: flex;
//               flex-direction: column;
//               tr {
//                 display: flex;
//                 flex: 1;
//                 td {
//                   flex: 1;
//                   border: thin solid #ddd;
//                   display: flex;
//                   justify-content: center;
//                 }
//                 .entrydate {
//                   max-width: 8%;
//                 }
//                 .description {
//                   max-height: 300px;
//                   overflow-y: hidden;
//                   text-overflow: ellipsis;
//                 }
//               }
//             }
//           }
//         }
//       }
//       .position-bottom {
//         // color: red;
//         margin-top: 100px;
//         .apexcharts-legend-text {
//           margin: 0px 8px;
//           font-size: 222px;
//           font-family: Helvetica, Arial, sans-serif;
//           // color: red;
//           // margin-top: 100px;
//           // height: 200px;
//           // background-color: red;
//         }
//       }
//       .apexcharts-menu-icon {
//         display: none;
//       }
//       // displ
//       .reportMenu {
//         min-height: 50px;
//         min-width: 100%;
//         display: flex;
//         flex-direction: row;
//         background-color: #fff;
//         // border-bottom-right-radius: 50px;
//         button {
//           width: 130px;
//           height: 40px;
//           border: none;
//           background-color: #3f3;
//           border-radius: 25px;

//           transition: 0.3s;
//           cursor: pointer;
//           &:hover {
//             transition: 0.3s;
//             box-shadow: 1px 2px 10px -2px #000;
//             border-width: 0px;
//             // width: 140px;
//             font-size: 14px;
//             transition: 0.3s;
//           }
//         }
//         .reportViews {
//           flex: 1;
//           button {
//             margin-left: 5%;
//             width: 40px;
//             height: 40px;
//             svg {
//               width: 70%;
//               height: 70%;
//             }
//           }
//         }
//         .reportExport {
//           flex: 1;
//           display: flex;
//           // background-color: red;
//           justify-content: flex-end;
//           button {
//             margin-right: 5%;
//           }
//         }
//       }
//     }
//     .reportPreviewHeader {
//       height: 10%;
//       background-color: #fff;
//       display: flex;
//       border-bottom: 2px solid #eee;
//       border-top-left-radius: 5px;
//       border-top-right-radius: 5px;
//       .reportPreviewLogo {
//         flex: 1;
//         display: flex;
//         .reportPreviewImage {
//           height: 80%;
//           width: 45%;
//           margin: auto;
//           display: flex;
//           img {
//             min-height: 100%;
//             max-height: 100%;
//             min-width: 100%;
//             max-width: 100%;
//           }
//         }
//       }
//       .reportPreviewNameAndHours {
//         flex: 9;
//         display: flex;
//         flex-direction: column;
//         .reportPreviewName {
//           flex: 1;
//           font-weight: 200;
//           font-size: 20px;
//         }
//         .reportPreviewHours {
//           flex: 1;
//           display: flex;
//           color: #888;
//           .reportPrevieTotal {
//             flex: 1;
//           }
//           .reportPrevieBillable {
//             flex: 1;
//           }
//           .reportPrevieNonBillable {
//             flex: 1;
//           }
//           .reportPreviewOvertime {
//             flex: 1;
//           }
//         }
//       }
//       .reportCloseContainer {
//         flex: 1;
//         display: flex;
//         border-top-right-radius: 5px;
//         // background-color: red;

//         .reportClose {
//           border-top-right-radius: 5px;
//           flex: 1;
//           cursor: pointer;
//           border: hidden;
//           font-size: 16px;
//           // border-radius: 50%;
//           // width: 50%;
//           height: 100%;
//           // margin-left: 5px;
//           color: #00dd21;
//           background-color: #fff;
//           border: none;
//           margin: auto;
//           cursor: pointer;
//           font-size: 19px;
//           font-weight: 800;
//           transition: 0.3s;
//           &:hover {
//             background-color: #eee;
//             transition: 0.3s;
//             color: red;
//           }
//         }
//       }
//     }
//   }
// }
// .StartCalendar {
//   height: 100%;
//   width: 100%;
//   background-color: rgba(0, 0, 0, 0.1);
//   z-index: 30;
//   position: fixed;
//   top: 0;
//   .calendar {
//     margin: auto;
//     margin-top: 15%;
//     height: 350px;
//     width: 50%;
//     border-radius: 10px;
//     border: 1px solid #ccc;
//   }
//   .calendarEnd {
//     margin: auto;
//     margin-top: 15%;
//     height: 350px;
//     width: 50%;
//     border-radius: 10px;
//     border: 1px solid #ccc;
//   }
// }
.ReportsContainer {
  max-width: 83%;
  // min-height: 600px;
  margin: auto;
  display: flex;
  flex-direction: column;
  margin-top: 10px;
  .DateOptions {
    display: flex;
    flex: 4;
    justify-content: space-around;
    .StartDate {
      flex: 1;
      display: flex;
      .startDateOptions {
        display: flex;
        flex-direction: row;
        margin: auto;
        justify-content: space-evenly;
        width: 70%;
        .DateLabels {
          display: flex;
          flex-direction: row;
          margin-top: 2px;
          margin-right: 7px;
        }
        h4 {
          padding: 0;
          margin: 0;
          display: flex;
          align-items: center;
          cursor: pointer;
          font-size: 17px;
          font-weight: 100;
          margin-bottom: 2px;
        }
        button {
          height: 30px;
          width: 30px;
          background-color: transparent;
          border: none;
          cursor: pointer;

          svg {
            margin: auto;
            height: 100%;
            width: 100%;
          }
        }
      }
    }
    .EndDate {
      flex: 1;
      display: flex;
      .endDateOptions {
        margin: auto;
      }
    }
    .IntervalOptions {
      flex: 1;
      display: flex;
    }
  }
  .ReportOptions {
    flex: 4;
    display: flex;
    flex-wrap: wrap;
    max-width: 100vw;
    // background-color: red;
    // margin-top: 10px;
    margin-left: -1%;
    .ReportMultiSelect {
      flex: 1;
      // margin: auto;
      margin-left: 1%;
      // display: grid;
      // grid-gap: -1px;
      // grid-template-columns: repeat(auto-fit, minmax(100px, 1fr));
      // grid-template-rows: repeat(4, 100px);
      min-width: 400px;
      .reportHeading {
        width: 100%;
        // background-color: red;
        padding-top: 1%;
        padding-bottom: 1%;
        display: flex;
        justify-content: center;
        h3 {
          padding: 0;
          margin: 0;
        }
      }
      .kn-multi_select__wrapper___30BEc {
        max-height: 400px;
        // width: 150%;
        // margin-left: -25%;
        // width: 150%;
        // margin-left: -25%;
      }
    }
  }
  .ReportSubmitOptions {
    display: flex;
    flex: 2;
    justify-content: center;
    .Submit {
      border-radius: 25px;
      border-style: solid;
      border-width: 0px;
      background-color: transparent;
      transition: 0.3s;
      background-color: #00dd21;
      height: 50px;
      cursor: pointer;
      width: 190px;
      transition: 0.3s;
      align-self: center;

      &:hover {
        background-color: #00dd21;
        transition: 0.3s;
        box-shadow: 1px 2px 5px #000;
        border-width: 0px;
        width: 200px;
        font-size: 14px;
        transition: 0.3s;
      }
    }
  }
}
