.hints {
  height: 100vh;
  justify-content: center;
  display: flex;
}
.blocker {
  height: 100%;
  width: 100%;
  background: rgba(17, 17, 17, 0.6);
  display: block;
  text-align: center;
  display: inline-block;
  float: left;
}
.LandingnavContainer {
  margin: 0px;
  display: flex;
  flex: 1;

  #bird {
    height: 50px;
    margin-top: 10px;
    margin-left: 30px;
  }
  #nameBrand {
    height: 35px;
    margin-left: 10px;
    flex: flex-start;
    margin-top: 15px;
  }
}
.LandingnavBar {
  width: 100%;
  height: 200px;
  margin: 0px;
  display: flex;
  background-image: linear-gradient(to bottom, rgba(10, 10, 10, 0.8), rgba(56, 56, 56, 0));
  position: absolute;
  z-index: 5;

  .Sign {
    flex: 1;
    justify-content: flex-end;
    display: flex;
    ul {
      // background-color: red;
      margin: 30px;
      list-style: none;
      list-style-type: none;
      text-decoration: none;
      // float: right;
      font-size: 18px;
      // width: 60px;
      height: 50px;
      display: flex;
      align-content: center;
      align-items: center;
      justify-content: center;
      justify-items: center;
      text-align: center;
      cursor: pointer;
      li {
        flex: 1;
        margin: 0;
        padding-right: 30px;
        color: white;
      }
    }
  }
}

.loginContainer {
  display: flex;
  height: 100%;
  width: 100%;
  position: fixed;
  z-index: 20;
  justify-content: flex-end;
  background: rgba(0, 0, 0, 0.2);
  .backToLogin{
    border-radius: 5px;
    background-color:#fff;
    display: flex;
    align-self: start;
    width: 45px;
    height: 3vh;
    border: none;
    svg{
      margin: auto;
      width: 25px;
      height: 2vh;
    }
    :hover{
      color: lime;
    }
  }
  #loginForm {
    background: #fff;
    border-radius: 8px;
    width: 400px;
    height: 300px;
    display: flex;
    flex-direction: column;
    margin: auto;
    justify-items: center;
    justify-content: center;
    align-items: center;
    align-content: center;
    top: 25%;
    left: 25%;
    h4 {
      flex: 1;
      display: flex;
      margin: 0;
      justify-content: center;
      text-align: center;
      align-items: center;
    }

    .passwordLogin {
      flex: 1;
      display: flex;
      align-items: center;
      width: 80%;
      margin-left: 50px;
      input[type="text"],
      input[type="password"] {
        padding: 0;
        margin: 0;
        border-radius: 10px;
        flex: 8;
        max-height: 40px;
        min-height: 40px;
        border: 1px solid #eee;
        text-align: center;

        transition: 0.3s;
        &:focus {
          outline: none;
          -webkit-transition: all 0.3s ease-in-out;
          -moz-transition: all 0.3s ease-in-out;
          -ms-transition: all 0.3s ease-in-out;
          -o-transition: all 0.3s ease-in-out;
          border-bottom-color: #00ff21;
          border-bottom-style: solid;
          border-bottom-width: thin;
          box-shadow: 0px 5px 5px -3px #00dd21;
        }
      }
      button {
        flex: 1;
        background: transparent;
        border: none;
        .eye {
          width: 55%;
          height: 55%;
          cursor: pointer;
          &:hover {
            color: rgb(62, 189, 53);
          }
        }
      }
    }
    #onSubmitButton {
      flex: 1;
      border-radius: 25px;
      border-style: solid;
      border-width: 0px;
      transition: 0.3s;
      background-color: #00dd21;
      max-height: 40px;
      min-height: 40px;
      width: 200px;
      margin-top: 25px;
      margin-bottom: 25px;
      cursor: pointer;
    }
  }
  .ref {
    font-size: 15px;
    border: none;
    background-color: inherit;
    cursor: pointer;
    &:hover {
      color: rgb(62, 189, 53);
    }
  }
}

.slider {
  width: 100vw;
  height: 100vh;
  box-shadow: 0px 0px 20px 5px grey;
  overflow: hidden;
}

.slider figure {
  position: relative;
  width: 400%;
  margin: 0px;
  display: flex;
  flex-direction: row;
  animation: webzone 10s infinite linear;
}

.slider figure img {
  width: 100vw;
  height: 100vh;
}
.backImage {
  background-image: url(../style/img/banner_img.jpg);
  min-height: 100%;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  div {
    background-image: linear-gradient(to bottom, rgba(10, 10, 10, 0.8), rgba(56, 56, 56, 0));
    img {
      display: flex;
      margin: auto;
    }
  }
}
