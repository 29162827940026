.moreButtons {
  display: flex;
  justify-content: space-around;
  bottom:10px;
  right: 25px;
  flex-direction: column;
  position: fixed;
  cursor: pointer;
  button {
    background-color: lime;
    border: 1px #00ff55;
    box-shadow: 1px 2px 5px #000;
    height: 50px;
    width: 50px;
    border-radius: 50%;
    margin-top: 10px;
    cursor: pointer;
    svg {
      flex: 1;
      min-width: 20px;
      min-height: 20px;
      max-width: 20px;
      max-height: 20px;
      padding: 0;
      margin: 0;
      margin: auto;
      cursor: pointer;
    }
  }
}
.addButton {
  label {
    margin-right: -10px;
    margin-left: 10%;
    margin-top: 30%;
  }
}

#manualChange {
  svg {
    min-height: 24px;
    min-width: 24px;
    margin-left: 14%;
  }
}

.DetailsCards {
  // background-color: red;
  #headingforsub {
    width: 100%;
    // background-color: red;
  }
}

.ContactFormContainer {
  display: flex;
  position: fixed;
  top: 0;
  z-index: 10;
  height: 100%;
  width: 100%;
  background: rgba(0, 0, 0, 0.2);
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  form {
    width: 700px;
    height: 500px;
    background-color: white;
    display: flex;
    margin: auto;
    margin-bottom: 5%;
    flex-direction: column;
    box-shadow: -5px 8px 15px #333;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
    .Heading {
      flex: 1;
      display: flex;
      background-color: rgba(0, 0, 0, 0.9);
      justify-content: flex-end;
      align-items: center;
      border-top-left-radius: 5px;
      border-top-right-radius: 5px;
      h3 {
        padding: 0;
        margin: 0;
        color: white;
        font-size: 18px;
        font-weight: 100;
        margin-right: 10px;
      }
      .CloseCus {
        border-radius: 50%;
        width: 25px;
        height: 25px;
        margin-right: 10px;
        color: #00dd21;
        background-color: #ededed;
        border: none;
        cursor: pointer;
        font-size: 15px;
        font-weight: 800;
        transition: 0.3s;

        &:hover {
          transition: 0.3s;
          color: red;
        }
      }
    }
    section {
      flex: 10;
      display: flex;
      border-top-left-radius: 5px;
      border-top-right-radius: 5px;
      border-bottom-left-radius: 5px;
      border-bottom-right-radius: 5px;
      .left {
        flex: 1;
        display: flex;
        flex-direction: column;
        align-items: center;

        input {
          display: block;
          height: 40px;
          width: 300px;
          font-size: 16px;
          border-style: none;
          border-bottom-color: #ededed;
          border-bottom-style: solid;
          border-bottom-width: thin;
          text-align: center;
          transition: 0.3s;
          background-color: transparent;
          margin-top: 0;
          padding: 0;
          margin: 0;
          padding-top: 5px;

          &:focus {
            outline: none;
            -webkit-transition: all 0.3s ease-in-out;
            -moz-transition: all 0.3s ease-in-out;
            -ms-transition: all 0.3s ease-in-out;
            -o-transition: all 0.3s ease-in-out;
            border-bottom-color: #00ff21;
            border-bottom-style: solid;
            border-bottom-width: thin;
            box-shadow: 0px 6px 10px -8px #00dd21;
          }
        }
      }
      .right {
        flex: 1;
        display: flex;
        flex-direction: column;

        section {
          flex: 1;
          display: flex;
          flex-direction: column;
          textarea {
            flex: 1;
            // max-height: 90%;
            max-width: 90%;
            // min-height: 90%;
            // min-width: 90%;
            margin-bottom: 5px;
            margin-right: 5px;
            border: #ededed solid thin;
            transition: 0.3s;
            resize: none;
          }
        }
        .UploadImage {
          flex: 1;
          margin: 0;
          padding: 0;
          min-height: 45%;
          min-width: 45%;
          max-height: 45%;
          max-width: 45%;
          display: flex;
          margin: auto;
          align-items: center;
          .logo {
            flex: 1;
            display: flex;
            height: 95%;
            width: 80%;
            margin: auto;
            img {
              height: 100%;
              width: 100%;
            }
            .remove {
              flex: 1;
              position: absolute;
              display: flex;
              img {
                width: 35px;
                height: 35px;
                cursor: pointer;
              }
            }
          }
        }
      }
    }
    .bottom {
      flex: 2;
      border-bottom-left-radius: 5px;
      border-bottom-right-radius: 5px;
      .AddContactSubmit {
        background-color: red;
        margin: auto;
        border-radius: 25px;
        border-style: solid;
        border-width: 0px;
        background-color: transparent;
        transition: 0.3s;
        background-color: #00dd21;
        height: 50%;
        width: 190px;
        transition: 0.3s;
        align-self: center;
        cursor: pointer;

        &:hover {
          background-color: #00dd21;
          transition: 0.3s;
          box-shadow: 1px 2px 5px #000;
          border-width: 0px;
          width: 200px;
          font-size: 14px;
          transition: 0.3s;
        }
      }
    }
  }
}

.MultiSelect {
  position: fixed;
  z-index: 100;
  min-height: 100%;
  min-width: 100%;
  background: transparent;
  top: 0;
  section {
    background-color: transparent;
    max-width: 0px;
    max-height: 0px;
    .multisel {
      position: fixed;
      // max-width : 200px;
      // max-height: auto;
      right: 90px;
      bottom: 40px;
    }
  }
}

.burgermenu {
  background-color: lime;
  box-shadow: 1px 2px 5px #000;
  border: 1px #00ff55;
  position: fixed;
  font-size: 20px;
  font-weight: bolder;
  min-height: 50px;
  min-width: 50px;
  max-height: 50px;
  max-width: 50px;
  border-radius: 50%;
  bottom: 25px;
  right: 25px;
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  justify-items: center;
  align-content: center;
  cursor: pointer;
  .hamburger {
    margin-top: 4px;
  }
  img {
    width: 100%;
    max-height: 20%;
    margin: auto;
    margin-left: -5%;
  }
  .burgerIcon {
    background-color: black;
    min-height: 20%;
    min-width: 20%;
  }
}

#heading {
  font-weight: 600;
  color: #333;
}
h1 {
  .backButton {
    background-repeat: no-repeat;
    border: none;
    cursor: pointer;
    overflow: hidden;
    outline: none;
    background-color: transparent;
    display: flex;
    svg {
      height: 20px;
      width: 20px;
      margin: auto;
    }
  }
}

.bottom {
  display: flex;
  flex: 1;
  .child3 {
    flex: 1;
    background-color: green;
    font-size: 20px;
  }
}
.editButton {
  background-color: lime;
  border: 1px #00ff88;
  position: fixed;
  font-size: 50px;
  height: 70px;
  width: 70px;
  border-radius: 50%;
  bottom: 25px;
  right: 25px;
  cursor: pointer;
  img {
    height: 55%;
    width: 65%;
    margin: auto;
    margin-top: 20%;
  }
}

.customerprofile {
  min-height: fit-content;
  max-width: 88%;
  margin: auto;
  display: flex;
  flex-direction: column;
  .cardCus {
    .cardtop {
      background: url("./img/bg1.jpg");
      margin: auto;
      max-height: 350px;
      max-width: 100%;
      min-height: 350px;
      min-width: 100%;
      display: flex;
      border-radius: 10px;
      box-shadow: 3px 5px 6px rgb(172, 168, 168);
      img {
        margin: auto;
        max-width: 200px;
        max-height: 200px;
        min-width: 200px;
        min-height: 200px;
        margin-top: 1.8%;
        border: 5px solid #ededed;
        border-radius: 50%;
      }
    }
  }
  .forDesc {
    background-color: #ededed;
    margin: auto;
    width: 80%;
    display: flex;
    margin-top: -90px;
    margin-bottom: 30px;
    border-radius: 8px;
    box-shadow: 3px 5px 20px -5px rgba(10, 9, 9, 0.3);
    text-align: center;
    flex: 1;
    flex-direction: column;
    .CompanyName {
      flex: 2;

      h1 {
        margin: auto;
        font-weight: 100;
        font-size: 35px;
        padding: 15px;
      }
    }

    .status {
      flex: 1;
      h2 {
        margin: auto;
        font-weight: 200;
        font-size: 22px;
        padding-bottom: 15px;
      }
    }

    .description {
      flex: 5;
      h3 {
        padding-bottom: 20px;
        margin: auto;
        width: 90%;
        font-weight: 100;
        font-size: 20px;
      }
    }

    .Address {
      flex: 1;
      background: rgba(0, 0, 0, 0.6);
      border-bottom-left-radius: 8px;
      border-bottom-right-radius: 8px;
      h3 {
        color: white;
        svg {
          color: white;
          max-height: 35px;
          max-height: 35px;
          min-height: 20px;
          min-height: 20px;
          margin-bottom: -2px;
          margin-left: 100x;
          margin-right: 15px;
        }
      }
    }
  }
}
