.navBar {
  position: sticky;
  top: 0;
  width: 100%;
  height: 100px;
  background-color: rgba(10, 10, 10, 0.8);
  z-index: 3;
}
.navContainer {
  width: 100%;
  height: 45px;
  margin: 0px;
  display: flex;
  background-color: rgba(10, 10, 10, 0.8);

  .user-info {
    flex: 1;
    display: flex;
    justify-content: flex-end;
    margin-right: 45px;
    margin-top: 0.5%;

    .info {
      margin-top: 0.4%;
      flex: 9;
      text-align: end;
      color: #fff;

      h4 {
        width: inherit;
        margin: 0;
        color: #fff;
        font-weight: 100;
      }
    }
    .userImage {
      display: flex;
      justify-content: center;
      flex: 1;
      max-height: 30px;
      max-width: 30px;
      min-height: 30px;
      min-width: 30px;
      // border-left:2px solid #ccc;
      cursor: pointer;
      margin-left: 10px;
      .MuiAvatar-colorDefault {
        margin-top: 1%;
        margin-left: -15%;
        max-height: 30px;
        max-width: 30px;

        p {
          font-size: 15px;
        }
      }
      img {
        margin-left: 15px;
        border-radius: 50%;
        max-height: 100%;
        max-width: 100%;
        min-height: 100%;
        min-width: 100%;
      }
    }
  }
  .UserProfileForm {
    width: 100%;
    height: 100%;
    z-index: 10;
    position: fixed;
    display: grid;
    place-items: center;
    align-items: center;
    transition: 0.3s;
    overflow-y: hidden;
    form {
      transition: 1s;
      height: auto;
      width: 27%;
      background-color: white;
      display: flex;
      flex-direction: column;
      padding-bottom: 20px;
      padding-top: 20px;
      border-radius: 5px;
      z-index: 10;
      position: relative;
      margin-left: auto;
      margin-right: 3%;
      margin-bottom: 27%;
      box-shadow: 0px 13px 26px -19px rgba(0, 0, 0, 0.75);

      .profileCardContainer {
        display: flex;
        width: 100%;
        height: 100%;
        flex-direction: column;
        .forImage {
          flex: 1;
          margin-left: auto;
          margin-right: auto;
          margin-top: 2%;
          max-height: 70px;
          max-width: 70px;
          min-height: 70px;
          min-width: 70px;
          img {
            border-radius: 50%;
            max-height: 100%;
            max-width: 100%;
            min-height: 100%;
            min-width: 100%;
          }
        }
        .forName {
          flex: 1;
          text-align: center;
        }
        hr {
          width: 80%;
          border-top: 1px solid rgb(231, 228, 228);
        }
      }
      .profileAndSignout {
        display: flex;
        width: 80%;
        height: 100%;
        align-self: center;
        flex-direction: column;
        cursor: pointer;
        h3{
          margin: auto;
        }
        svg{
          width: 20px;
          height: 20px;
        }
        :hover {
          color: lime;
        }
        .toProfile {
          flex: 1;
          display: flex;
          .forIcon {

            flex: 1;
          }
          img{
            flex: 1;
            width: 30px;
            height: 30px;
            border-radius: 50%;
          }
          .forHeading {
            flex: 12;
          }
          :hover {
            color: lime;
          }
          .cusName{
            flex: 12;
            display: flex;
            align-items: center;
            justify-items: flex-start;
            h3{
              padding-left: 10px;
              margin-left: 0;
            }
          }
        }
        .toSignout {
          flex: 4;
          display: flex;
          .forIcon {
            flex: 1;
          }
          .forHeading {
            flex: 12;
          }
        }
      }
    }
  }
}

.navContainer #bird {
  height: 30px;
  margin-top: 5px;
  margin-left: 20px;
}
.navContainer #nameBrand {
  height: 25px;
  margin-left: 10px;
  flex: flex-start;
  margin-top: 10px;
}

.navContainer ul {
  float: right;
  margin: 8px;
  margin-right: 38px;
  list-style: none;
  list-style-type: none;
}

.navContainer ul li {
  color: #efefef;
  font-size: 16px;
}

.navContainer ul li label {
  justify-content: flex-end;
  font-size: 13px;
}

.navBar {
  height: 34px;
  color: #efefef;
  margin-top: 0px;
}

.navBar ul {
  color: black;
  list-style: none;
  list-style-type: none;
  margin: 5px;
  margin-right: 20px;
  top: 0;
  float: right;
}

.navBar ul li:first-child {
  float: left;
  color: #efefef;
}
.navBar ul:hover li {
  /* transform: scale(1.2); */
  transition: 0.3s;
}

.navBar ul li:hover {
  transform: scale(1.2);
  transition: 0.2s;
  opacity: 1;
}

.navBar .link:hover {
  transform: scale(1.1);
  transition: 0.3s;
}

.navBar ul li {
  font-size: 15px;
  text-align: center;
  padding: 0px;
  margin-right: 15px;
  margin-left: 15px;
  padding: 10px;
  padding-top: 0;
  transition: 0.3s;
  color: black;
  /* border-style: solid;
     border-width: medium;
     border-color: white; */
}

.navBar ul li {
  color: black;
  transition: 0.3s;
  z-index: -1;
  margin-top: 0;
}

div [role="navigation"] {
  height: 45px;
  // background-color: red;
}

button {
  cursor: pointer;
}
