.morebuttons {
  display: flex;
  justify-content: space-around;
  bottom: 25px;
  right: 25px;
  flex-direction: column;
  position: fixed;
  .custom {
    svg {
      margin-bottom: 20%;
      margin-left: -10%;
      max-height: 5px;
      max-width: 5px;
    }
  }
  button {
    cursor: pointer;
    background-color: lime;
    border: 1px #00ff55;
    box-shadow: 1px 2px 5px #000;
    height: 50px;
    width: 50px;
    border-radius: 50%;
    margin-top: 10px;
    label {
      // margin-top:-4/0%;
      font-size: 18px;

      // background-color: red;
    }
    svg {
      flex: 1;
      min-width: 20px;
      min-height: 20px;
      max-width: 20px;
      max-height: 20px;
      padding: 0;
      margin: 0;
      margin: auto;
    }
  }
}
.cloneButton {
  border: none;
  cursor: pointer;
  background-color: lime;
  border-radius: 5px;
  svg {
    height: 15px;
    width: 15px;
  }
}
.MultiSelect {
  position: fixed;
  z-index: 100;
  min-height: 100%;
  min-width: 100%;
  background: transparent;
  top: 0;
  section {
    background-color: transparent;
    max-width: 0px;
    max-height: 0px;
    .multisel {
      position: fixed;
      // max-width : 200px;
      // max-height: auto;
      right: 90px;
      bottom: 40px;
    }
  }
}

#Serviceheading {
  flex-direction: row;
  display: flex;
  min-height: 23px;
  align-items: center;
  width: 83%;
  margin: auto;
  font-size: 20px;
  flex-wrap: wrap;
  font-size: 25px;
  font-weight: 400;
  .ServiceHeading {
    flex: 1;
    button {
      border: none;
      background-color: transparent;
      cursor: pointer;
      svg {
        height: 20px;
        width: 20px;
        margin: auto;
      }
    }
  }
  .SearchHeading {
    flex: 1;
    display: flex;
    justify-content: flex-end;
    padding-right: 5%;
    input {
      // flex:5;
      width: 200px;
      margin-top: 1px;
      border: none;
      font-size: 16px;
      // background:red;
      margin-top: 5px;
    }
    .searchIcon {
      // flex:1;
      max-height: 25px;
      svg {
        height: 80%;
        margin-bottom: 5px;
        padding: 0;
      }
    }
  }
}
.editServiceForm {
  z-index: 50;
  background: rgba(0, 0, 0, 0.2);
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  display: flex;
  justify-content: center;
  align-content: center;
  form {
    min-height: 280px;
    width: 50%;
    margin: auto;
    display: flex;
    flex-direction: column;
    background-color: rgba(255, 255, 255, 0.9);
    border-radius: 8px;

    .editServiceFormSubmit {
      flex: 2;
      //   background-color: red;
      display: flex;
      justify-content: space-around;
      button {
        cursor: pointer;
        border-radius: 25px;
        border-style: solid;
        border-width: 0px;
        background-color: transparent;
        transition: 0.3s;
        background-color: #00dd21;
        height: 50px;
        width: 200px;
        transition: 0.3s;
        margin-bottom: 2%;
        margin-top: 2%;
        &:hover {
          background-color: #00dd21;
          transition: 0.3s;
          box-shadow: 1px 2px 5px #000;
          border-width: 0px;
          width: 210px;
          font-size: 15px;
          transition: 0.3s;
        }
      }
    }
    .editServiceFormHeader {
      flex: 2;
      background: rgb(30, 30, 30);
      display: flex;
      justify-content: flex-end;
      justify-items: center;
      align-items: center;
      border-top-left-radius: 8px;
      border-top-right-radius: 8px;
      min-height: 35px;
      h3 {
        font-size: 18px;
        color: white;
        font-weight: 100;
        margin: 0;
        padding: 0;
        margin-right: 5px;
      }
      button {
        border-radius: 50%;
        width: 25px;
        height: 25px;
        margin-right: 8px;
        color: #00dd21;
        background-color: #ededed;
        border: none;
        cursor: pointer;
        font-size: 15px;
        font-weight: 800;
        transition: 0.3s;

        &:hover {
          transition: 0.3s;
          color: red;
        }
      }
    }

    .editServiceFormContent {
      flex: 4;
      //   background-color: rgba(255, 255, 255, 0.8);
      display: flex;
      flex-direction: column;
      //   justify-content: center;
      align-items: center;
      input {
        //   min-height: 30px;
        //   max-width: 70%;
        //   min-width: 70%;
        display: block;
        height: 40px;
        width: 300px;
        font-size: 16px;
        border-style: none;
        border-bottom-color: #ededed;
        border-bottom-style: solid;
        border-bottom-width: thin;
        text-align: center;
        transition: 0.3s;
        background-color: transparent;
        margin-top: 0;
        padding: 0;
        margin: 0;
        padding-top: 5px;
        margin-bottom: 20px;
        &:focus {
          outline: none;
          -webkit-transition: all 0.3s ease-in-out;
          -moz-transition: all 0.3s ease-in-out;
          -ms-transition: all 0.3s ease-in-out;
          -o-transition: all 0.3s ease-in-out;
          border-bottom-color: #00ff21;
          border-bottom-style: solid;
          border-bottom-width: thin;
          box-shadow: 0px 6px 10px -8px #00dd21;
        }
      }
      textarea {
        min-height: 60px;
        max-width: 70%;
        min-width: 70%;
        &:focus {
          outline: none;
          -webkit-transition: all 0.3s ease-in-out;
          -moz-transition: all 0.3s ease-in-out;
          -ms-transition: all 0.3s ease-in-out;
          -o-transition: all 0.3s ease-in-out;
          border-bottom-color: #00ff21;
          border-bottom-style: solid;
          border-bottom-width: thin;
          box-shadow: 0px 6px 10px -8px #00dd21;
        }
      }

      .MultiSelActviities {
        min-width: 98%;
        margin-top: 2%;
      }
    }
  }
}
.ServiceListContainer {
  display: flex;
  height: auto;
  width: 83%;
  margin: auto;
  flex-direction: column;
  .selecteddescription {
    margin: auto;
    background: rgba(200, 200, 200, 0.1);
    // min-height: 60px;
    border-radius: 10px;
    display: flex;
    justify-content: center;
    text-align: center;
    padding: 20px;
  }
  h1 {
    font-weight: 200;
    text-align: center;
    font-size: 20px;
    margin: auto;
    margin-top: 20px;
  }
  .ServiceItem {
    min-width: 80vw;
    height: 70px;
    margin: 10px;
    margin-left: 0;
    margin-right: 0;
    display: flex;
    text-indent: 20px;
    font-size: 18px;
    font-weight: 100;
    color: #666;
    background-color: rgba(255, 255, 255, 0.5);
    transition: 0.3s;
    border: none;
    cursor: pointer;
    &:hover {
      border: 0px;
      transition: 0.1s;
      background-color: rgba(160, 160, 160, 0.05);
      box-shadow: 0px 8px 20px -20px #333;
      -webkit-transform: translateY(-50%);
      transform: translateY(-3%);
      -webkit-transition-delay: 0.1s;
      transition-delay: 0.1s;
      // background: (10, 9, 9, 0.75);
    }

    .SName {
      flex: 3;
      border-right: thin solid #ddd;
      display: flex;
      align-items: center;
    }
    .SDescription {
      flex: 4;
      overflow: hidden;
      display: flex;
      align-items: center;
      text-align: center;
    }
    .CloneButton {
      border: none;
      flex: 0.5;
      background-color: transparent;
      cursor: pointer;
      transition: 0.3s;
      &:hover {
        transition: 0.2s;
        // background-color: rgba(0, 0, 0, 0.1);
        svg {
          transition: 0.2s;
          height: 16px;
          width: 16px;
        }
      }
    }
  }
}
