.timesheetheader {
    width: 100%;
    height: 50px;
    display: flex;
    flex-direction: row;
    .HeaderCustomer {
        // background-color: red;
        display: flex;
        flex: 1;
        padding-left: 3%;

        .heading {
            margin: auto;
            font-size: 25px;
            font-weight: 400;
        }
        #heading {
            flex: 1;
            display: flex;
            align-items: center;
        }
        .timesheetheadercustomer {
            flex: 1;
            display: flex;
            align-items: center;

            .dropdown {
                display: inline;
                flex: 1;
                position: relative;
                display: inline-block;
                min-width: 1%;
                max-width: 1%;
                &:hover {
                    .dropdown-content {
                        cursor: default;
                        display: block;
                    }
                }
                .dropbtn {
                    font-size: 16px;
                    border: none;
                    background-color: #fff;
                    h1 {
                        margin: auto;
                        font-size: 25px;
                        font-weight: 400;
                    }
                }
                .dropdown-content {
                    display: none;
                    position: absolute;
                    background-color: #f1f1f1;

                    min-width: 160px;
                    box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
                    z-index: 1;

                    #Customers {
                        color: black;
                        padding: 12px 16px;
                        text-decoration: none;
                        display: block;
                    }

                    #Customers:hover {
                        background-color: #ddd;
                    }
                }
            }
        }
    }
    .TimeandDate {
        display: flex;
        flex: 1;
        .timesheetheaderTime {
            flex: 2;
            // background-color: red;
            font-size: 10px;
            justify-content: flex-end;
            display: flex;
            align-items: center;
            .heading {
                font-size: 25px;
                font-weight: 400;
            }
        }
        .timesheetheaderDate {
            display: flex;
            align-items: center;
            cursor: pointer;
            .arrows {
                display: flex;
                align-items: center;
                align-content: center;
                h1 {
                    font-size: 25px;
                    padding: 0;
                    margin: 0;
                    font-weight: 400;
                    text-align: center;
                }
                svg {
                    margin-bottom: -25%;
                    padding: 2px;
                }
            }
            @media screen and (min-width: 543px) {
                h1 {
                    font-size: 24px;
                }
            }
            @media screen and (max-width: 400px) {
                h1 {
                    font-size: 24px;
                }
            }
        }
    }
}
