.skillcardContainer {
	flex: 1;
	display: flex;
	flex-direction: column;
	max-width: 100%;
	min-width: 100%;
	.btnAddSkill { 
		height: 50px;
		width: 80%;
		// border-radius: 50px;
		cursor: pointer;
		border: none;
		background-color: rgba(0, 0, 0, 0.1);
		transition: 0.3s;
		margin: auto;
		font-size: 20px;
		svg{
			font-size: 30px;
			// color: green;
			font-weight: 100;
		}
		 &:hover {
		 	transition: 0.3s;
		 }
	}
	.showaddform {
		height: 100%;
		width: 100vw;
		position: fixed;
		z-index: 5;
		top: 0;
		left: 0;
		background-color: rgba(0, 0, 0, 0.6);
		display: flex;
		align-content: center;
		.addskill {
			// min-width: 10%;
			// max-width: 10%;
			// min-height: 50px;
			min-height: 35%;
			margin: auto;
			background-color: #eee;
			display: flex;
			flex-direction: column;
			min-width: 30%;
			max-width: 50%;
			border-bottom: 1px solid #eee;
			border-top: 1px solid #eee;
			display: flex;
			align-items: center;
			border-radius: 5px;
			align-content: center;
			.closeSkills {
				align-self: flex-end;
				background-color: transparent;
				border: none;
				&:hover {
					color: red;
					font-size: 15px;
				}
			}
			.skillname {
				// background-color: red;
				flex: 1;
				width: 100px;
				display: block;
				max-height: 20px;
				// width: 300px;
				font-size: 16px;
				border-style: none;
				border-bottom: 1px solid;
				// border-bottom-style: solid;
				// border-bottom-width: thin;
				// text-align: center;
				transition: 0.3s;
				background-color: transparent;
				padding: 20px;
				margin: auto;
				// margin-top: 0;
				margin-top: 5%;
				text-indent: 14px;
				text-align: center;
				// margin-left: 5%;
			}
			.skillyears {
				flex: 1;
				width: 100px;
				display: block;
				max-height: 20px;
				// width: 300px;
				font-size: 16px;
				border-style: none;
				text-align: center;
				// border-bottom-color: #ededed;
				border-bottom-style: solid;
				// border-bottom-width: thin;
				// text-align: center;
				transition: 0.3s;
				background-color: transparent;
				padding: 20px;
				// margin: auto;
				// margin-top: 0;
				// padding-top: 5px;
				// border-bottom: 1px solid;
				// margin-left: 5%;
				// margin-right: 5%
			}
			.skillrating {
				label {
					padding: 5%;
				}
				// background-color: rgb(94, 86, 86);
				flex: 1;
				// background-color: blue;
				min-width: 50px;
				display: flex;
				align-items: center;
				flex-direction: column;
				margin-top: 2%;
			}
			.skillbutton {
				margin-bottom: 10px;
				height: 50px;
				width: 150px;
				border-radius: 50px;
				cursor: pointer;
				border: none;
				background-color: lime;
				transition: 0.3s;
				&:hover {
					height: 55px;
					width: 155px;
					transition: 0.3s;
				}
			}
		}
	}

	.skillarray {
		display: flex;
		flex: 1;
		// background-color: red;
		flex-direction: row;
		align-items: flex-start;
		align-content: flex-start;
		flex-wrap: wrap;
		margin-top: 3%;
		// display: grid;
		// grid-template-columns: 20% 20% 20% 20%;
		// grid-template-rows: 60px 60px 60px;
		// grid-column-gap: 10px;
		overflow-x: hidden;
		overflow-y: auto;
		// margin-left: 15%;
		max-height: 270px;
		max-width: 100%;
		min-width: 100%;
		.skilllist {
			// width: 30px;
			min-width: 95%;
			max-width: 95%;
			min-height: 45px;
			max-height: 45px;
			// background-color: rgba(100, 100, 100, 0.2);
			border-radius: 15px;
			display: flex;
			flex-direction: row;
			align-content: center;
			align-items: center;
			cursor: pointer;
			margin: 1%;
			color: #333;
			.skillsName {
				flex: 3;
				text-align: start;
				text-indent: 10px;
			}
			.skillYears {
				border-left: 1px solid #aaa;
				flex: 1;
			}
			.skillRating {
				flex: 1;
				border-left: 1px solid #aaa;
			}
			.removeskill {
		border-radius: 50%;
        width: 25px;
        height: 25px;
        margin-left: 9px;
        color: #2e332f;
        background-color: #ededed;
        border: none;
        cursor: pointer;
        font-size: 15px;
        font-weight: 800;
        transition: 0.3s;

        &:hover {
          transition: 0.3s;
          color: red;
		  font-size:17px;
		  width: 30px;
		  height: 30px;
				}
			}
		}
	}
}
