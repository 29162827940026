
body {
    /* font-weight: 200; */
}
strong {
    font-weight: 600;
}
a {
    color: #211e20;
    text-decoration: none;
}
h1,
h2,
h3,
h4,
h5,
h6 {
    color: #211e20;
    font-weight: 200;
}
img {
    max-width: 100%;
}
.text-center {
    text-align: center;
}
.text-right {
    text-align: right;
}
.container {
    max-width: 1140px;
    width: 90%;
    margin: 0 auto;
    position: relative;
}
.row:after,
.row:before {
    content: "";
    display: block;
    clear: both;
}
.site-header {
    background-color: #211e20;
}
.site-header .container {
    width: 95%;
}
.site-header .logo {
    float: left;
    width: 40%;
    position: relative;
}
.site-header .logo .mobile-toggle {
    height: 40px;
    width: 40px;
    background-color: transparent;
    border: none;
    font-size: 0;
    background-image: url(../style/img/menu-toggle.png);
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    margin: auto;
    display: none;
}
.site-header .header-right {
    float: right;
    width: 60%;
}
.menu-bar {
    text-align: right;
}
.menu-bar > ul {
    list-style: none;
    margin-top: 20px;
}
.menu-bar > ul > li {
    display: inline-block;
    padding: 10px;
}
.menu-bar > ul > li a {
    color: #fff;
    padding: 10px;
    text-decoration: none;
    font-weight: 300;
    display: block;
    transition: all 0.3s ease-in-out;
    -webkit-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
}
.menu-bar > ul > li a img {
    vertical-align: middle;
    margin: -5px 0 -5px 5px;
}
.menu-bar > ul > li a:hover,
.menu-bar > ul > li.active a {
    background-color: #5f903f;
}

.header-banner img {
    display: block;
    width: 100%;
}
.header-banner h1 {
    position: absolute;
    bottom: 70px;
    color: #fff;
    font-size: 80px;
    line-height: 1;
}
.header-banner h1 span {
    display: block;
    padding: 0px 12px 15px;
    margin-left: -16px;
    border: 4px solid #507933;
    line-height: 1;
}
.section-heading {
    font-size: 48px;
    text-align: center;
    color: #5f903f;
}
.section-heading strong {
    color: #211e20;
}
section.services-section {
    padding: 70px 0;
}
.services-section .section-heading {
    text-transform: uppercase;
}
.watermark-box {
    font-family: "Poppins", sans-serif;
    font-weight: 200;
    max-width: 950px;
    margin: 50px auto 0;
    padding: 30px;
    border: 2px solid #000;
    background-position: center;
    background-image: url(../style/img/watermark-logo.png);
    background-repeat: no-repeat;
}
.watermark-box p {
    padding-bottom: 15px;
}
ul.icons-list {
    text-align: center;
    padding: 0 20px;
    max-width: 1240px;
    margin: 30px auto 20px;
}
ul.icons-list li {
    display: inline-block;
    padding: 6px;
    width: 19%;
    min-width: 230px;
    margin-right: -4px;
}
ul.icons-list li .icons-box {
    display: block;
    padding: 20px;
    background-color: #fff;
    box-shadow: 0 5px 20px rgba(0, 0, 0, 0.05);
    -webkit-box-shadow: 0 5px 20px rgba(0, 0, 0, 0.05);
    -o-box-shadow: 0 5px 20px rgba(0, 0, 0, 0.05);
    max-width: 240px;
    transition: all 0.3s ease-in-out;
    border-radius: 10px;
}
ul.icons-list li .icons-box:hover {
    box-shadow: 0 5px 20px rgba(0, 0, 0, 0.15);
    -webkit-box-shadow: 0 5px 20px rgba(0, 0, 0, 0.15);
    -o-box-shadow: 0 5px 20px rgba(0, 0, 0, 0.15);
    transform: translateY(-10px);
    -webkit-transform: translateY(-10px);
    -o-transform: translateY(-10px);
}
ul.icons-list li .icons-box p {
    font-size: 25px;
    color: #5f903f;
}
ul.icons-list li .icons-box p strong {
    display: block;
}
section.case-study {
    padding: 30px 0 70px;
}
.case-study .case-img {
    max-width: 90%;
    display: block;
    margin-left: auto;
    margin-bottom: -15px;
    margin-right: 20px;
}
.case-study .img-box {
    border: 1px solid #e5e5e5;
}
.case-study .case-study-img1 {
    margin-bottom: 5px;
}
.case-study .img-box img {
    display: block;
    width: 100%;
}
.case-study .upper-box {
    background-image: url(../style/img/watermark-logo.png);
    background-repeat: no-repeat;
    background-position: center;
    padding: 15px 30px 25px;
    text-align: center;
    background-color: #88b743;
    margin-bottom: 5px;
}
.case-study .upper-box h3 {
    color: #fff;
    font-size: 45px;
}
.case-study .upper-box p {
    color: #fff;
    font-size: 18px;
}
.case-study .upper-box p a {
    color: #fff;
    font-size: 14px;
    font-weight: 300;
    text-decoration: underline;
}
section#contact-sale {
    padding: 50px 0;
}
.contact-sale {
    padding: 50px 0 100px;
}
.contact-sale .container-box {
    align-items: center;
    background-color: #ececec;
    max-width: 1600px;
    width: 95%;
}
.contact-sale .container-box .content-box {
    text-align: center;
    max-width: 500px;
    margin: auto;
    padding: 20px;
}
.contact-sale .container-box .content-box h3 {
    font-size: 45px;
}
.contact-sale .container-box .content-box p {
    padding-bottom: 15px;
}
.contact-sale .container-box .content-box p a {
    color: #517c36;
    font-size: 14px;
    font-weight: 300;
    text-decoration: underline;
}

#contact-sale .section-heading {
    position: relative;
    margin-bottom: 30px;
}
#contact-sale .section-heading:after {
    content: "";
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    height: 2px;
    width: 90px;
    background-color: #000;
    margin: auto;
}

.contact-form {
    padding: 0 40px;
}
.contact-form .form-group {
    margin-bottom: 15px;
}
.contact-form .form-group .forn-control {
    display: block;
    width: 100%;
    height: 40px;
    border-left: none;
    border-top: none;
    border-right: none;
    border-bottom: 1px solid #e8e8e8;
    resize: none;
    color: #000;
    font-size: 13px;
    font-family: "Poppins", sans-serif;
}
.contact-form .form-group textarea.forn-control {
    max-width: 100%;
    min-width: 100%;
    height: 100px;
    max-height: 150px;
    min-height: 70px;
}
/* FOr Placeholder color */
::placeholder {
    color: #000;
    opacity: 1;
}
:-ms-input-placeholder {
    color: #000;
}
::-ms-input-placeholder {
    color: #000;
}

.default-btn {
    display: inline-block;
    padding: 10px 15px;
    font-weight: 200;
    border: 2px solid #517c36;
    background-color: #517c36;
    color: #ffffff;
    font-family: "Poppins", sans-serif;
    font-size: 17px;
    cursor: pointer;
    transition: all 0.3s ease-in-out;
    -webkit-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    text-decoration: none;
}
.default-btn:hover {
    background-color: #ffffff;
    color: #517c36;
}
.default-btn.dark-btn {
    background-color: #211e20;
    border-color: #211e20;
}
.default-btn.dark-btn:hover {
    background-color: #ffffff;
    color: #211e20;
}
.contact-content {
    padding: 30px 50px;
    max-width: 460px;
    margin: 0 auto;
}
.contact-content p {
    font-size: 30px;
    font-weight: 300;
    margin-bottom: 40px;
    color: #253449;
}
.contact-content p:last-child {
    margin-bottom: 0;
    font-size: 25px;
}

.sub-footer {
    max-width: 1000px;
    margin: 0 auto;
    background-color: #ffffff;
    padding: 50px 80px;
    box-shadow: 0 0px 25px rgba(0, 0, 0, 0.05);
    -webkit-box-shadow: 0 0px 25px rgba(0, 0, 0, 0.05);
    -o-box-shadow: 0 0px 25px rgba(0, 0, 0, 0.05);
    margin-bottom: -100px;
    position: relative;
    z-index: 9;
    width: 80%;
}
.sub-footer .d-flex {
    align-items: center;
}
.sub-footer h3 {
    font-size: 28px;
    font-weight: 500;
    margin-bottom: 10px;
}
.sub-footer h3 span {
    color: #517c36;
}
.sub-footer p {
    color: #7f858e;
    font-size: 17px;
    font-weight: 300;
}
footer.main-footer {
    background-color: #211e20;
    color: #fff;
    padding-top: 160px;
}
.foot-widget {
    padding: 0 40px 40px;
    font-weight: 300;
}
.foot-widget.f-widget-1 {
    padding-left: 0;
}
.foot-widget .title {
    color: #fff;
    font-size: 24px;
    font-weight: 300;
    margin-bottom: 15px;
}
.foot-widget a {
    color: #ffffff;
    text-decoration: none;
}
.foot-widget .let-talk-btn {
    font-size: 40px;
    line-height: 1.5;
    font-weight: 200;
}
.foot-widget .let-talk-btn img {
    margin-left: 10px;
    width: 30px;
    vertical-align: middle;
}
.copyright p {
    padding: 10px;
    text-align: center;
    font-size: 12px;
    font-weight: 300;
    border-top: 1px solid #646263;
}
@media (min-width: 981px) {
    .d-flex {
        display: -ms-flexbox;
        display: flex;
        -ms-flex-wrap: wrap;
        flex-wrap: wrap;
    }
    .col-5 {
        -ms-flex: 0 0 50%;
        flex: 0 0 50%;
        max-width: 50%;
    }

    .col-6 {
        -ms-flex: 0 0 60%;
        flex: 0 0 60%;
        max-width: 60%;
    }
    .col-4 {
        -ms-flex: 0 0 40%;
        flex: 0 0 40%;
        max-width: 40%;
    }
    .col-3 {
        -ms-flex: 0 0 33%;
        flex: 0 0 33%;
        max-width: 33%;
    }
    .case-study .case-study-img1 img {
        display: none;
    }
    .case-study .case-study-img1 {
        background-image: url(../style/img/image-1.jpg);
        height: 100%;
        background-size: cover;
        margin-right: 5px;
    }
    .contact-sale .container-box .img-box {
        margin: -50px 0;
    }
}

@media (max-width: 1200px) {
    .menu-bar > ul > li {
        padding-right: 0;
        padding-left: 0;
    }
    ul.icons-list li {
        min-width: 180px;
    }
    ul.icons-list li .icons-box p {
        font-size: 18px;
    }
    ul.icons-list li .icons-box img {
        max-width: 80px;
    }
}

@media (max-width: 980px) {
    .foot-widget {
        padding-left: 0;
        padding-right: 0;
    }
    .sub-footer .text-right {
        text-align: left;
        margin-top: 30px;
    }
    .contact-content {
        margin: 0;
        padding-left: 0;
        padding-right: 0;
    }
    .contact-form {
        padding: 0;
    }
    section.case-study .container {
        max-width: 500px;
    }
    .case-study .case-img {
        margin: 0 auto -10px;
    }
    .contact-sale .container-box {
        background-color: #fff;
        width: 100%;
    }
    .contact-sale .container-box .content-box {
        background-color: #ececec;
    }
    .contact-sale .container-box .img-box {
        text-align: center;
    }
    .contact-sale {
        padding: 0 0 50px;
    }
    section#contact-sale {
        padding: 0px;
    }
    section.services-section {
        padding: 50px 0 20px;
    }
    .header-banner h1 {
        font-size: 60px;
    }
    ul.icons-list {
        max-width: 650px;
    }
    .section-heading {
        font-size: 35px;
    }
    .case-study .upper-box h3,
    .contact-sale .container-box .content-box h3 {
        font-size: 36px;
    }
    .contact-content p {
        font-size: 24px;
        margin-bottom: 20px;
    }
    .contact-content p:last-child {
        font-size: 20px;
    }
    .site-header .logo,
    .site-header .header-right {
        width: 100%;
        float: none;
    }
    .site-header .logo .mobile-toggle {
        display: block;
    }
    .menu-bar {
        text-align: left;
        position: absolute;
        left: 0;
        right: 0;
        background-color: #000;
        padding: 20px;
        height: 0;
        opacity: 0;
        overflow: hidden;
        transition: all 0.3s ease-in-out;
        z-index: 99999;
    }
    .menu-bar.active {
        height: auto;
        opacity: 1;
        overflow: hidden;
    }
    .menu-bar > ul {
        margin-top: 0;
    }
    .menu-bar > ul > li {
        display: block;
        padding: 0;
    }
}

@media (max-width: 767px) {
    .header-banner h1 {
        font-size: 50px;
        bottom: 30px;
    }
    .sub-footer {
        padding: 30px 40px;
        width: 90%;
    }
    .case-study .case-img {
        margin: 0 auto 0;
    }
}
@media (max-width: 480px) {
    .header-banner h1 {
        font-size: 26px;
        bottom: 10px;
    }
    .header-banner h1 span {
        padding: 0px 6px 9px;
        margin-left: -7px;
        border: 2px solid #507933;
    }
    .section-heading {
        font-size: 22px;
        line-height: 1;
    }
    ul.icons-list li {
        min-width: 140px;
    }
    ul.icons-list li .icons-box {
        padding: 10px;
    }
    ul.icons-list li .icons-box p {
        font-size: 15px;
    }
    .case-study .upper-box h3,
    .contact-sale .container-box .content-box h3 {
        font-size: 28px;
    }
    .case-study .upper-box p {
        font-size: 16px;
    }
    #contact-sale .section-heading {
        padding-bottom: 15px;
    }
    .sub-footer {
        padding: 30px 20px;
    }
    .sub-footer h3 {
        font-size: 20px;
    }
    .sub-footer p {
        font-size: 15px;
    }
    .foot-widget .title {
        font-size: 20px;
    }
    .foot-widget p {
        font-size: 14px;
    }
    .foot-widget .let-talk-btn {
        font-size: 24px;
    }
    .site-header .logo img {
        max-width: 70%;
    }
}
