.timesheetRight{
    .slotContainer{
        
        .slotHour{
            order:3;
            border-left: 1px solid #ccc;
            border-right: none;
        }
        .linedivider{
            order: 2;
        }
        .slotBody{
            order:1;
            .slotItemContainer{
                .hoveredCard {
                    margin-left: -300px;
                    margin-top: -6vh;
                }
            }
        }
    }
}