.assistantManagerContainer{
    flex: 1;
    max-height: 100vh;
    min-height: 85vh;
    width: 90%;
    display:flex;
    margin-left: 5%;
    background-color: transparent;
    margin-top:0.5%;
    
    // #chart{
    //     &::-webkit-scrollbar{
    //         width: 0;
    //     }
    // }

    .customers{
        flex:1;
        display: flex;
        flex-direction: column;
        border: 0.5px solid #ccc;
        justify-content: center;
        align-items: center;
        background-color: rgba(10, 9, 9, 0.75);
        border:none;

        .customer{
            flex:1;
            display: flex;
            align-content: center;
            margin-top:2%;
            padding-bottom:2%;
            border: none;
            border-bottom: 1px solid #777;
            width: 90%;

            background: none;
            color: inherit;
            padding: 0;
            font: inherit;
            cursor: pointer;
            outline: inherit;
            // background: #fff;
            h1{
                margin:auto;
                // height: 100px;
                color:#fff;
                font-weight: 100;
                font-size: 20px;
            }
        }
    }
    .stats{
        flex:3;
        display:flex;
        align-content: center;
        flex-direction: column;
        // max-width: 60vw;
        max-height: 90vh;
        overflow-y: auto;
        &::-webkit-scrollbar {
            height: 0px;
            width: 0px;
        }
        // background-color: aqua;
        h1{
            font-weight: 100;
            font-size: 30px;
            width:90%;
            height: 50px;
            // border-bottom: 1px solid #999;
            margin-left:4%;
        }
        .dataCharts{
        
            margin-left: 1%;
            flex:1;
            display:flex;
            
            min-height: 75%;
            flex-wrap: wrap;
            border-bottom: 1px solid black;
    
            .donutChart{
                min-height: 98%;
                min-width: 80%;
            }
           
        }
        .report{
            flex:1;
            display:flex;
            flex-direction: column;
            // background: red;
            .options{
                flex:1;
                display: flex;
                justify-content: space-between;

                *{
                    border-radius: 8px;
                    width: 150px;
                    border: none;
                    text-align-last: center;
                    background: #eee;
                    box-shadow: 1px 2px 4px #777;
                }
            }
            .generated-reports{
                flex:15;
                // background: blue;
            }
        }
    }
   
}
