.companyCard {
  min-height: 200px;
  min-width: 100%;
  background: rgba(255, 255, 255, 0.5);
  display: flex;
  flex-direction: row;
  margin-bottom: 5px;
  // margin-top:110px;
  transition: 0.3s;
  border-style: solid;
  border-width: thin;
  border-color: #dfdfdf;
  align-content: center;

  .isActive {
    max-height: 100%;
    max-width: 1%;
    flex: 1;
  }
  .companyMiniCard {
    flex: 4;
    max-width: 95%;
    min-height: 85%;
    max-height: 85%;
    margin: auto;
    // background-color: red;
    display: flex;
    // margin-top: 0;

    .companyImage {
      flex: 1;
      // min-height: 95%;
      // max-width: 30%;
      background-color: #ededed;
      // height:10px;
      object-fit: fit;
      display: flex;
      // border-radius: 50%;
      align-self: center;
      // content:url("./img/building.jpg");
      img {
        flex: 1;
        max-height: 100%;
        max-width: 100%;
        min-height: 100%;
        min-width: 100%;
        // border-radius: 50%;
      }
    }
    .leftside {
      flex: 3;
      display: flex;
      min-width: 45%;
      margin: 1%;
      flex-direction: column;
      border-right: 2px solid #ededed;

      input {
        display: block;
        height: 40px;
        font-size: 16px;
        border-style: none;
        border-bottom-color: #ededed;
        border-bottom-style: solid;
        border-bottom-width: thin;
        text-align: left;
        transition: 0.3s;
        background-color: transparent;
        margin-top: 0;
        padding: 0;
        margin: 0;
        padding-top: 5px;
        margin-bottom: 20px;
        &:focus {
          outline: none;
          -webkit-transition: all 0.3s ease-in-out;
          -moz-transition: all 0.3s ease-in-out;
          -ms-transition: all 0.3s ease-in-out;
          -o-transition: all 0.3s ease-in-out;
          border-bottom-color: #00ff21;
          border-bottom-style: solid;
          border-bottom-width: thin;
          box-shadow: 0px 6px 10px -8px #00dd21;
        }
      }

      textarea {
        resize: none;
        border: 1px solid rgba(0, 0, 0, 0.1);
      }
      .companyName {
        flex: 3;
        font-size: 25px;
        font-weight: thin;
        // max-height: 34px;
        color: black;
        // background-color: red;
      }
      .companyArea {
        flex: 1;
        font-size: 19px;
        max-height: 26px;
        font-weight: 100;
      }
      .companyDesc {
        flex: 5;
        font-size: 14px;
        max-height: 100%;
        position: relative;
        z-index: 0;
        overflow: hidden;
        line-height: 18px;
      }
    }
    .rightside {
      flex: 1;
      display: flex;
      flex-direction: column;
      flex-wrap: wrap;
      // align-content: center;
      .companyAddr {
        flex: 1;
        // margin: auto;
        p {
          font-size: 16px;
          margin-bottom: 1.5%;
          max-width: 100%;
        }
        input {
          display: block;
          height: 20px;
          font-size: 16px;
          border-style: none;
          border-bottom-color: #ededed;
          border-bottom-style: solid;
          border-bottom-width: thin;
          text-align: left;
          transition: 0.3s;
          background-color: transparent;
          margin-top: 0;
          padding: 0;
          margin: 0;
          padding-top: 5px;
          margin-bottom: 20px;
          &:focus {
            outline: none;
            -webkit-transition: all 0.3s ease-in-out;
            -moz-transition: all 0.3s ease-in-out;
            -ms-transition: all 0.3s ease-in-out;
            -o-transition: all 0.3s ease-in-out;
            border-bottom-color: #00ff21;
            border-bottom-style: solid;
            border-bottom-width: thin;
            box-shadow: 0px 6px 10px -8px #00dd21;
          }
        }
      }
    }
  }
}
.edit {
  float: right;
  height: 45px;
  width: 45px;
  margin-top: -25px;
  background-color: red;
  cursor: pointer;
}
