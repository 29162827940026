.LoadingContainer {
  display:flex;
  height:100%;
  width:100%;
  position:fixed;
  z-index:20;
  background-color: rgba(255,255,255,.5);
  overflow-y : hidden;
  top:0;
  left:0;
  .lds-ellipsis {
    display: inline-block;
    position: relative;
    width: 80px;
    height: 80px;
    margin:auto;
  }
  .lds-ellipsis div {
    position: absolute;
    top: 33px;
    width: 13px;
    height: 13px;
    border-radius: 50%;
    background: #47da23;
    animation-timing-function: cubic-bezier(0, 1, 1, 0);
  }
  .lds-ellipsis div:nth-child(1) {
    left: 8px;
    animation: lds-ellipsis1 0.6s infinite;
  }
  .lds-ellipsis div:nth-child(2) {
    left: 8px;
    animation: lds-ellipsis2 0.6s infinite;
  }
  .lds-ellipsis div:nth-child(3) {
    left: 32px;
    animation: lds-ellipsis2 0.6s infinite;
  }
  .lds-ellipsis div:nth-child(4) {
    left: 56px;
    animation: lds-ellipsis3 0.6s infinite;
  }
  @keyframes lds-ellipsis1 {
    0% {
      transform: scale(0);
    }
    100% {
      transform: scale(1);
    }
  }
  @keyframes lds-ellipsis3 {
    0% {
      transform: scale(1);
    }
    100% {
      transform: scale(0);
    }
  }
  @keyframes lds-ellipsis2 {
    0% {
      transform: translate(0, 0);
    }
    100% {
      transform: translate(24px, 0);
    }
  }
}

//   display:flex;
//   height: 100%;
//   width: 100%;
//   .lds-dual-ring {
//     display: inline-block;
//     width: 80px;
//     height: 80px;
//     // background-color: red;
//     margin: auto;
//   }
//   .lds-dual-ring:after {
//     content: " ";
//     display: block;
//     width: 50%;
//     height: 50%;
//     // margin: 8px;
//     border-radius: 50%;
//     border: 6px solid #48c429;
//     border-color: #48c429 transparent #48c429 transparent;
//     animation: lds-dual-ring 1.2s linear infinite;
//   }
//   @keyframes lds-dual-ring {
//     0% {
//       transform: rotate(0deg);
//     }
//     100% {
//       transform: rotate(360deg);
//     }
//   }
