.timesheetEntry {
    background-color: rgba(0, 0, 0, 0.1);
    // background-color: red;
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 5;
    display: flex;
    justify-content: center;
    align-items: center;
    .entrywindowcontainer {
        width: 70%;
        height: 85%;
        background-color: #fefefe;
        border-radius: 25px;
        display: flex;
        flex-direction: column;
        .dropdown {
            display: flex;
            flex: 1;
            position: relative;
            display: inline-block;
            &:hover {
                .dropdown-content {
                    cursor: default;
                    display: block;
                }
            }
            .dropbtn {
                border: none;
                background-color: #fff;
                h1 {
                    margin: auto;
                    font-size: 23px;
                    font-weight: 400;
                }
            }
            .dropdown-content {
                display: none;
                position: absolute;
                background-color: #f1f1f1;
                // min-width: 160px;
                box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
                z-index: 1;
                max-height: 60vh;
                // overflow-y: scroll;
                /* Links inside the dropdown */
                #times {
                    color: #555;
                    padding: 12px 16px;
                    text-decoration: none;
                    display: block;
                    &:hover {
                        color: #111;
                    }
                }
            }
        }

        .top {
            flex: 1;
            // background-color: red;
            display: flex;
            justify-content: flex-end;
            .customerh {
                // background-color: red;
                flex: 1;
                display: flex;
                justify-content: center;
                align-items: center;

                .dropdown {
                    display: inline-block;
                    // display: flex;
                    flex: 1;
                    position: relative;
                    // background-color: red;
                    max-width:20%;
                    justify-content: center;
                    align-items: center;
                    // margin: auto;
                    &:hover {
                        .dropdown-content {
                            cursor: default;
                            display: block;
                        }
                    }
                    .dropbtn {
                        border: none;
                        background-color: #fff;

                        h1 {
                            // margin: auto;
                            font-size: 23px;
                            font-weight: 400;
                        }
                    }
                    .dropdown-content {
                        display: none;
                        position: absolute;
                        // margin-top: 15vh;
                        background-color: #f1f1f1;
                        // min-width: 160px;
                        box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
                        z-index: 1;
                        max-height: 60vh;
                        overflow-y: scroll;
                    }
                }
            }
            .closebutton {
                // height: 100%;
                font-size: 25px;
                padding-right: 20px;
                padding-left: 20px;
                margin-top: 0.5%;
                background-color: transparent;
                border: none;
                position: absolute;
            }
        }
        .middle {
            flex: 10;
            display: flex;
            .Condor360Container {
                flex: 1;
                display: flex;
                flex-direction: column;
                color: #333;
                .timeoptions {
                    flex: 1;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    .timeblock {
                        height: 40px;
                        width: 40px;
                        background-color: #ddd;
                        // background-color: blue;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        margin-left: 10px;
                        margin-right: 10px;
                        padding-left: 10px;
                        padding-right: 10px;
                        border-radius: 5px;
                        transition: 0.5s;
                        flex-direction: column;
                        &:hover {
                            transition: 0.1s;
                            box-shadow: 0px 10px 15px -7px rgba(0, 0, 0, 0.5);
                            cursor: pointer;
                            margin-top: -5px;
                        }

                        label {
                            font-weight: 600;
                        }
                        .timetimeblock {
                            font-weight: 400;
                            border-top: 1px solid #999;
                        }
                    }
                }

                .timeDropDowns {
                    flex: 1;
                    display: flex;
                    max-width: 90%;
                    min-width: 90%;
                    margin: auto;
                    padding-top: 1%;
                    justify-content: space-evenly;
                    label {
                        flex: 1;
                    }
                    .timestarted {
                        flex: 1;
                        display: flex;
                        justify-content: space-between;
                        align-items: center;
                    }
                    .timefinished {
                        flex: 1;
                        display: flex;
                        justify-content: space-evenly;
                        align-items: center;
                    }
                }
                .dropdown {
                    display: flex;
                    flex: 1;
                    position: relative;
                    display: inline-block;
                    &:hover {
                        .dropdown-content {
                            cursor: default;
                            display: block;
                        }
                    }
                    .dropbtn {
                        border: none;
                        background-color: #fff;
                        h1 {
                            margin: auto;
                            font-size: 23px;
                            font-weight: 400;
                        }
                    }
                    .dropdown-content {
                        display: none;
                        position: absolute;
                        background-color: #f1f1f1;
                        // min-width: 160px;
                        box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
                        z-index: 1;
                        max-height: 60vh;
                        overflow-y: scroll;
                        /* Links inside the dropdown */
                        #times {
                            color: #555;
                            padding: 12px 16px;
                            text-decoration: none;
                            display: block;
                            &:hover {
                                color: #111;
                            }
                        }
                    }
                }

                .CustomersServicesActivities {
                    margin: auto;
                    margin-top: 10px;
                    padding-top: 10px;
                    border-top: 1px solid #ddd;
                    max-width: 90%;
                    min-width: 90%;
                    max-height: 40%;
                    min-height: 40%;
                    flex: 4;
                    display: flex;
                    flex-direction: row;
                    justify-content: space-evenly;
                    div {
                        min-width: 30%;
                        .checklisheader {
                            flex: 1;
                        }
                        .checklist {
                            flex: 9;
                            .kn-multi_select__wrapper___30BEc {
                                // max-height: 50%;
                                min-height: 50%;
                                overflow-y: hidden;
                            }
                        }
                    }
                }

                .billingtype {
                    flex: 2;
                    display: flex;
                    max-width: 90%;
                    min-width: 90%;
                    margin: auto;

                    .billable {
                        // background-color: yellow;
                        flex: 1;
                        display: flex;
                        justify-content: space-evenly;
                        align-items: center;
                    }
                    .overtime {
                        flex: 1;
                        // background-color: blue;
                        display: flex;
                        justify-content: space-evenly;
                        align-items: center;
                    }
                }

                .description {
                    flex: 4;
                    // background-color: red;
                    display: flex;
                    max-width: 80%;
                    min-width: 80%;
                    margin: auto;
                    border-radius: 25px;
                    justify-content: center;
                    border: 1px solid #aaa;
                    textarea {
                        flex: 1;
                        max-width: 95%;
                        resize: none;
                        border: none;
                    }
                }
                .bottom {
                    flex: 1.5;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    #del {
                        &:hover {
                            background-color: red;
                        }
                    }
                    button {
                        height: 70%;
                        width: 15%;
                        border-radius: 25px;
                        background-color: rgba(0, 0, 0, 0.1);
                        color: #777;
                        transition: 0.4s;
                        &:hover {
                            transform: 0.1s;
                            background-color: lime;
                            color: #000;
                            box-shadow: 0px 10px 15px -7px rgba(0, 0, 0, 0.5);
                            margin-top: -2px;
                        }
                    }
                }
            }
        }
    }
}
