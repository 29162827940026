

.report{
    flex: 1;
    max-height: 100vh;
    min-height: 88%;
    width: 90%;
    display:flex;
    margin: auto;
    // background-color: red;
    margin-top:0.5%;
    .userDets{
        background-color: rgba(10, 9, 9, 0.75);
        flex:1;
        display: flex;
        flex-direction: column;
        // border-right: 0.5px solid black;
        // background-color: rgba(0, 0, 0, 0.7);
        justify-content: center;
        align-items: center;
        .imagery{
            flex: 1;
            min-height: 50%;
            margin: auto;
            min-height:200px;
            align-items:center ;
            display: flex;
            flex-direction: column;
            // background-color: rgba(0,0,0,.76);
            border-bottom: 1px solid #777;
            width: 90%;
            img{
                // min-width: 80%;
                min-height: 90%;
                max-width: 80%;
                max-height: 90%;
                object-fit: contain;
                margin:auto;

            }
        }

        .lists{
            // background: red;
            flex:1;
            color:white;
            display: flex;
            flex-direction: column;
            border: 0.5px solid #ccc;
            // justify-content: center;
            // align-items: center;
            border:none;
            width: 100%;
            text-indent: 20px;
            h3{
                // background-color: aqua;
                padding: 0;
                // color: ;
            }
            ul{
                // background: red;
                list-style: none;
                text-indent: 10px;
                padding:0;
                margin:auto;
                width: 90%;
                background: #777;
                border-radius:20px;
                // border-left:1px solid #777;
                // border-top-radius: 20px;
                li{
                    background: rgba(10, 9, 9, 0.75);
                    font-size: 17px;
                    padding:8px;
                    border-bottom:1px solid #777;
                    border: 1px solid #777;
                    border-top:none;
                    transition: .3s;

                    &:hover{
                        transition: .3s;
                        // box-shadow: 0px 5px 15px 3px #333;
                        -webkit-transform: translateY(-50%);
                        transform: translateY(-5%);
                        /* opacity: 1; */
                        -webkit-transition-delay: 0.1s;
                        transition-delay: 0.1s;
                        border-color: #777;
                        // background: red;
                        // color:white
                    }
                }
                :first-child{
                    border: 1px solid #777;
                    // background: red;
                    border-top-left-radius: 20px;
                    border-top-right-radius: 20px;
                }
                li:last-child{
                    border: 1px solid #777;
                    border-top:none;
                    border-bottom-left-radius: 20px;
                    border-bottom-right-radius: 20px;
                }
            }


            .Incomplete{
                // background-color: teal;
                flex:1;
                flex-direction: column;
                .IncompleteHeader{
                    padding: 0;
                }
                .IncompleteList{
                    // background: blue;
                    flex: 5;
                }
            }
            .Complete
            {
                flex:1;
                display: flex;
                // background-color: red;
                flex-direction: column;

                .CompleteHeader{
                    flex: 1;
                }
                .CompleteList{
                    flex: 5;
                    // display: flex;
                }
                
                
            }
    
            // h3{
            //     flex:1;
            //     display: flex;
            //     align-content: center;
            //     margin-top:2%;
            //     padding-bottom:2%;
            //     border: none;
            //     // border-bottom: 1px solid #777;
            //     width: 90%;
    
            //     background-color: blue;
            //     color: white;
            //     padding: 0;
            //     font: inherit;
            //     cursor: pointer;
            //     outline: inherit;
            //     // background: #fff;
            // }
            // h1{
            //     margin:auto;
            //     // height: 100px;
            //     color:#fff;
            //     font-weight: 100;
            //     font-size: 20px;
            //     margin:0;
            // }
        }
    }
    .rhs{
        flex:2;
        display: flex;
        flex-direction: column;
        overflow-y: auto;
        #heading{
            margin:0;
            // margin-left: 7%;
            padding: 0;
            text-indent:0%;
            background: rgba(10, 9, 9, 0.75);
            color:white;
        }
        .dataCharts{
            margin-left: 1%;
            flex:4;
            display:flex;
            
            // overflow: auto;
            flex-wrap: wrap;
            min-height: 60%;
            
            .donutChart{
                flex:1;
                min-height: 100%;
                
            }
        }
        .reportCard{
            flex:1;
            display:flex;
            background-color: teal;
            flex-direction: column;
            min-height:100%;
            .timeSummary{
                flex:1;
                display:flex;
                flex-direction: column;
                background-color: pink;
                .gridhead{
                    flex:1;
                    max-height:70px;
                    display:flex;
                    background-color: blue;
                    .date{
                            flex:1;
                            border: 1px solid black;
                        }
                        .time{
                            flex:2;
                            display :flex;
                            flex-direction: column;
                            border: 1px solid black;
                            .times{
                                flex:1;
                                // max-height:15px;
                                display:flex;   
                                .startTime{
                                    flex:1;
                                    border-right: 1px solid black;
                                }
                                .endTime{
                                    flex:1;
                                }
                            }
                        }
                        .customer{
                            flex:2;
                            border: 1px solid black;
                        }
                        .service{
                            flex:2;
                            border: 1px solid black;
                        }
                        .description{
                            flex:4;
                            border: 1px solid black;
                        }
                }
            }
            .datafill{
                flex:5;
                display:flex;
                background-color: red;
                .data{
                    flex:1;
                }
            }
        }
       
    }
    
}
.click{
    height: 100px;
    widows: 100px;
    background: black;
}