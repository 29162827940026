.backImage {
    background-image: url(../style/img/banner_img.jpg);
    width: 100%;
    min-height: 100%;
    div {
      img {
        display: flex;
        margin: auto;
      }
    }
  }
.resetpassoword {
    width: 100%;
    height: 100%;
    z-index: 10;
    position: fixed;
    display: grid;
    place-items: center;
    align-items: center;
    transition: 0.3s;
    overflow-y: hidden;
    top: 0;

    form {
        height: 350px;
        width: 500px;
        background-color: #fff;
        margin-top: -100px;
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        place-items: center;
        border-radius: 5px;
        z-index: 10;

        .AddUserSubmit {
            border-radius: 25px;
            border-style: solid;
            border-width: 0px;
            background-color: transparent;
            transition: 0.3s;
            background-color: #00dd21;
            height: 50px;
            width: 200px;
            cursor: pointer;
            transition: 0.3s;
        }
        .AddUserSubmit:hover {
            background-color: #00dd21;
            transition: 0.3s;
            box-shadow: 1px 2px 5px #000;
            border-width: 0px;
            width: 205px;
            font-size: 15px;
            transition: 0.3s;
        }

        input {
            width: 60%;
            border-style: none;
            border-bottom-color: #555;
            border-bottom-style: solid;
            border-bottom-width: thin;
            text-align: center;
            transition: 0.3s;
            font-size: 16px;
        }
        input:focus {
            outline: none;
            -webkit-transition: all 0.3s ease-in-out;
            -moz-transition: all 0.3s ease-in-out;
            -ms-transition: all 0.3s ease-in-out;
            -o-transition: all 0.3s ease-in-out;
            border-bottom-color: #00ff21;
            border-bottom-style: solid;
            border-bottom-width: thin;
            box-shadow: 0px 5px 5px -3px #00dd21;
        }
    }
}

