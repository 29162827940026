.timesheetLeft,
.timesheetRight {
    flex: 1;
    display: flex;
    flex-direction: column;
    max-width: 47%;
    // background-color: red;
    cursor: pointer;
    .slotContainer {
        // background-color: red;
        min-height: 6vh;
        max-height: 6vh;
        flex: 1;
        margin-top: 5px;
        margin-bottom: 5px;
        display: flex;
        flex-direction: row;
        transition: 0.4s;
        background-color: rgba(220, 220, 220, 0.1);
        border-radius: 10px;

        &:hover {
            // transition: 0.2s;
            // min-height: 9vh;
            background-color: rgba(0, 0, 0, 0.1);
            border-radius: 10px;
            .slotBody {
                div {
                    label {
                        display: flex;
                    }
                }
            }
        }
        .slotHour {
            flex: 1;
            border-right: 1px solid #ccc;
            display: flex;
            justify-content: center;
            align-items: center;
            font-size: 20px;
            // color:#aaa;
        }
        .slotBody {
            flex: 9;
            display: flex;
            flex-direction: row;
            .timeonly {
                display: flex;
                justify-content: center;
                align-items: center;
                flex: 1;
                label {
                    cursor: pointer;
                    flex: 1;
                    display: none;
                    justify-content: center;
                    align-items: center;
                    height: 100%;
                    // border-radius: 25px;
                    color: #777;
                    &:hover {
                        color: #222;
                    }
                    .serviceslot {
                        font-size: 11px;
                        color: #000;
                        position: absolute;
                        font-weight: 350;
                        margin-top: 45px;
                    }
                }
            }
            #slottaken {
                label {
                    display: flex;
                    color: lime;
                    justify-content: center;
                    // background-color: blue;
                    overflow-x: hidden;
                    p {
                        overflow: hidden;
                        flex: 1;
                        display: flex;
                        max-width: 9vw;
                        // color:#222;
                        .scrolling {
                            animation: marquee 6s linear infinite;
                            white-space: nowrap;
                        }

                        @keyframes marquee {
                            from {
                                transform: translateX(10%);
                            }
                            to {
                                transform: translateX(-10%);
                            }
                        }
                    }
                }
            }
            .slotItemContainer {
                flex-direction: column;
                background-color: rgba(210, 210, 210, 0.2);
                // border-right: 1px solid #ddd;
                // border-left: 1px solid #ddd;
                // padding-left: 1px;
                // padding-right: 1px;
                // flex: 1;
                // max-width: 10vw;
                // min-width: 10vw;
                display: flex;
                // align-items: center;
                overflow: hidden;
                transition: 2s;

                &:hover {
                    transition: 2s;
                    .hoveredCard {
                        display: flex;
                        transition: 2s;
                    }
                }
                .hoveredCard {
                    display: none;
                    background-color: white;
                    border-radius: 8px;
                    box-shadow: 0 0 10px 0px rgba(210, 210, 210, 0.9);
                    height: 200px;
                    width: 300px;
                    position: absolute;
                    margin-left: 10vw;
                    margin-top: -6vh;
                    flex-direction: column;
                    transition: 2s;
                    z-index: 1;
                    div {
                        border: none;
                        color: #777;
                    }
                    .hovercustomercont {
                        display: flex;
                        flex-direction: row;
                        // background-color: red;
                        flex: 1;
                        max-width: 90%;
                        min-width: 90%;
                        margin: auto;
                        div {
                            color: black;
                        }
                        .hovercust {
                            flex: 2;
                            font-size: 17px;
                            font-weight: 500;
                            display: flex;
                            align-items: center;
                        }
                        .time {
                            flex: 1;
                            // background-color: blue;
                            display: flex;
                            justify-content: center;
                            align-items: center;
                        }
                    }
                    .hoverservice {
                        // background-color: blue;
                        display: flex;
                        align-items: center;
                        font-weight: 400;
                        font-size: 15px;
                        flex: 0.9;
                        max-width: 90%;
                        min-width: 90%;
                        margin: auto;
                        color: #767676;
                    }
                    .hoveractivity {
                        // background-color: yellow;
                        display: flex;
                        flex: 0.8;
                        align-items: flex-start;
                        font-size: 12px;
                        max-width: 90%;
                        min-width: 90%;
                        margin: auto;
                    }
                    .hoverbilling {
                        display: flex;
                        flex: 0.8;
                        align-items: flex-start;
                        font-size: 12px;
                        max-width: 90%;
                        min-width: 90%;
                        margin: auto;
                        color: #777;
                    }
                    .hoverdescription {
                        // background-color: green;
                        flex: 4;
                        background-color: rgba(240, 240, 240, 0.5);
                        // max-height: 80%;
                        max-width: 90%;
                        min-width: 90%;
                        border-radius: 5px;
                        margin: auto;
                        margin-bottom: 1.6vh;
                        color: #222;
                        // word-break: ;
                        text-overflow: ellipsis;
                        overflow-y: auto;
                        font-size: 12px;
                    }
                    .hoveredit {
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        .hoverbutton {
                            background-color: white;
                            border: none;
                            font-size: 13px;
                            padding: 10px;
                            margin-top: -3%;
                            &:hover {
                                color: red;
                            }
                        }
                    }
                }
                div {
                    flex: 1;
                    font-size: small;
                    color: #444;
                    // width: 100%;
                    display: flex;
                    justify-content: flex-start;
                    // background-color: red;
                    border-left: 1px solid rgba(100, 100, 100, 0.1);
                    p {
                        margin: 0;
                        padding: 0;
                    }
                    div {
                        // display: flex;
                        flex: 1;
                        justify-content: flex-start;
                        text-indent: 2px;
                    }
                }
                .container {
                    overflow: hidden;
                    flex: 1;
                    display: flex;
                    max-width: 100%;
                    .scrolling {
                        animation: marquee 5s linear infinite;
                        white-space: nowrap;
                    }

                    @keyframes marquee {
                        from {
                            transform: translateX(100%);
                        }
                        to {
                            transform: translateX(-100%);
                        }
                    }
                }
                .slotcustomer {
                    display: flex;
                    flex-direction: row;
                    .container {
                        display: flex;

                        align-items: center; // justify-content: center;
                        text-align: center;
                        // background-color: red;
                    }
                    .slottime {
                        // background-color: blue;
                    }
                }
                .slotservice {
                }
                .slotactivity {
                    font-weight: 300;
                }
            }
        }
    }
}
