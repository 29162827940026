.calContainer {
    height: 100%;
    width: 100%;
    background: rgba(0, 0, 0, 0.3);
    z-index: 20;
    top: 0;
    display: flex;
    justify-content: flex-end;
    right: 0;
    .calendar {
        border-radius: 10px;
        border: 1px solid #ccc;
        margin-right: 1%;
        margin-top: 5%;
        height: 350px;
        width: 35%;
    }
    .cal {
        margin-top: 5%;
        height: 100%;
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: flex-end;
    }
}
