.card {
	background-color: rgba(255, 255, 255, 0.5);
	h3 {
		display: flex;
		justify-content: center;
		padding-top: 15px;
	}
	.MuiAvatar-colorDefault {
		justify-content: center;
		margin-top: 45px;
		max-height: 100px;
		max-width: 100px;
	}
}
.card:hover {
	transition: 0.3s;
	box-shadow: 0px 20px 15px -8px rgba(160, 160, 160, 0.6);
	-webkit-transform: translateY(-50%);
	transform: translateY(-3%);
	/* opacity: 1; */
	-webkit-transition-delay: 0.1s;
	transition-delay: 0.1s;
	background: (10, 9, 9, 0.75);
	// border:3px solid black;
	/* color:white */
}

.card:hover .cardImage img {
	height: 83%;
	width: 83%;
	transition: 0.3s;
	border-radius: 0%;
	/* background: rgba(0,0,0,.76); */
}

.card .cardImage img {
	border-radius: 100%;
}

.card:hover:after,
.card.hover:after {
	-webkit-transform: rotateX(0);
	transform: rotateX(0);
	background: rgba(10, 9, 9, 0.75);
}

.listcard {
	min-width: 83vw;
	max-width: 83vw;
	height: 50px;
	padding: 5px;
	display: flex;
	text-indent: 20px;
	font-size: 16px;
	font-weight: 100;
	color: #666;
	background-color: rgba(255, 255, 255, 0.5);
	transition: 0.3s;
	border: none;
	margin: auto;
	&:hover {
		border: 0px;
		transition: 0.1s;
		background-color: rgba(160, 160, 160, 0.05);
		box-shadow: 0px 8px 20px -20px #333;
		-webkit-transform: translateY(-50%);
		transform: translateY(-3%);
		-webkit-transition-delay: 0.1s;
		transition-delay: 0.1s;
		// background: (10, 9, 9, 0.75);
	}
	.userImage {
		flex: 0.7;
		border-right: thin solid #ddd;
		display: flex;
		justify-content: center;
		.MuiAvatar-colorDefault {
			margin-top: 5%;
			// margin-left: -5%;
			p {
				margin-right: 45%;
			}
		}

		img {
			border-radius: 50%;
			margin: auto;
			min-height: 40px;
			min-width: 40px;
			max-height: 40px;
			max-width: 40px;
			background-color: white;
		}
	}
	.userName {
		flex: 2;
		display: flex;
		border-right: thin solid #ddd;
		align-items: center;
		justify-content: flex-start;
		flex-direction: row;
		max-width: 15vw;
		// background-color: blue;
		.userName_names{
			// background-color: yellow;
			white-space: nowrap;
			overflow: hidden;
			text-overflow: ellipsis;
			margin:0;
		}
		.skills_listcard{
			margin:0;
			color:rgb(184, 184, 184);
		// 	background-color: red;
			font-style: italic;
			white-space: nowrap;
			overflow: hidden;
			text-overflow: ellipsis;
			margin:0;;
		}
	}
	.email {
		flex: 3;
		display: flex;
		border-right: thin solid #ddd;
		align-items: center;
		// background:red;
		align-content: center;
		white-space: nowrap;
		overflow: hidden;
		text-overflow: ellipsis;
	}
	.mobile{
		flex: 2;
		display: flex;
		border-right: thin solid #ddd;
		align-items: center;
		white-space: nowrap;
		overflow: hidden;
		text-overflow: ellipsis;
		max-width: 10vw;
	}
	.userPosition {
		flex: 3;
		display: flex;
		align-items: center;
		white-space: nowrap;
		overflow: hidden;
		text-overflow: ellipsis;
		text-indent:inherit;
		label{
			flex:5;
			height:100%;
			display: flex;
			align-items: center;
			overflow: hidden;
			text-overflow: ellipsis;
			word-break: normal;
			text-indent:inherit;
		}
		.deleteContact{
			height:100%;
			flex:3;
			display: flex;
			justify-items: flex-end;
			align-items: center;
			img{
				height:30px;
				width:35px;
			}
		}
	}
	.indicator {
    flex: 0.1;
    border-radius: 50%;
	height:8px;
	max-width:8px;
	margin:auto;
	}
}
