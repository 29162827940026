.mother {
  height: 100%;
  width: 100%;
  z-index: 100;
  position: fixed;
  top: 0;
  left: 0;
  background-color: rgba(0,0,0,.4);
  display: flex;
  align-content: center;
  .comfirmDialog {
    height: 155px;
    max-width: 40%;
    flex: 1;
    transition: 0.3s;
    background: #efefef;
    display: flex;
    flex-direction: column;
    margin: auto;
    align-content: center;
    border-radius: 5px;
    h4 {
      text-align: center;
    }
    .comfirmDialogButtons {
        margin: auto;
      .comfirmDialogCancel {
        margin: auto;
        margin-right: 5px;
        flex: 1;
        border-radius: 25px;
        border-style: solid;
        border-width: 0px;
        background-color: transparent;
        transition: 0.3s;
        background-color: #00dd21;
        height: 200%;
        cursor: pointer;
        width: 90px;
        transition: 0.3s;
        align-self: center;

        &:hover {
          background-color: #00dd21;
          transition: 0.3s;
          box-shadow: 1px 2px 5px #000;
          border-width: 0px;
          font-size: 14px;
          transition: 0.3s;
        }
      }
      .comfirmDialogDelete {
        margin: auto;
        margin-left: 5px;
        flex: 1;
        border-radius: 25px;
        border-style: solid;
        border-width: 0px;
        background-color: transparent;
        transition: 0.3s;
        background-color: red;
        height: 200%;
        cursor: pointer;
        width: 90px;
        transition: 0.3s;
        align-self: center;
        color: white;

        &:hover {
          background-color: red;
          transition: 0.3s;
          box-shadow: 1px 2px 5px #000;
          border-width: 0px;
          font-size: 14px;
          transition: 0.3s;
        }
      }
    }
  }
}
