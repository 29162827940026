#heading {
	color: #333;
	font-size: 25px;
	font-weight: 400;
}
h1 {
	.backButton {
		background-repeat: no-repeat;
		border: none;
		cursor: pointer;
		overflow: hidden;
		outline: none;
		background-color: transparent;

		svg {
			height: 20px;
			width: 20px;
			margin: auto;
		}
	}
	.Saved{
		color:rgb(96, 228, 96);
		// background-color: blue;
		width: 64%;
		display: flex;
		justify-content: flex-end;
		align-items: center;
		font-size: 17px;

	}
}

.showcustomers {
	background-color:rgba(0, 0, 0, .2);
	padding:20px;
	min-height:100%;
	min-width: 100%;
	position: fixed;
	z-index: 2;
	// top:0;
display: flex;
	.showcustomerscont {
		// background-color: red;
		height: 50%;
		width: 500px;
		padding: 10px;

		position: fixed;
		bottom:0;
		right: 5%;
	}
}
.mainmenu {
	flex: 1;
	display: flex;
	justify-content: flex-end;
	align-items: center;
	flex-direction: column;
	position: fixed;
	bottom: 0;
	right: 1%;

	button {
		background-color: lime;
		height: 50px;
		width: 50px;
		margin-bottom: 10px;
		border: none;
		border-radius: 50px;
		box-shadow: 1px 2px 5px #000;
		svg {
			width: 20px;
			height: 20px;
		}
	}
}

.bottom {
	display: flex;
	flex: 1;
	.child3 {
		flex: 1;
		background-color: green;
		font-size: 20px;
	}
}
.editButton {
	background-color: lime;
	border: 1px #00ff88;
	position: fixed;
	font-size: 50px;
	height: 70px;
	width: 70px;
	border-radius: 50%;
	bottom: 25px;
	right: 25px;

	img {
		height: 55%;
		width: 65%;
		margin: auto;
		margin-top: 20%;
	}
}

.myprofile {
	min-height: fit-content;
	max-width: 88%;
	margin: auto;
	display: flex;
	flex-direction: column;
	.cardprofile {
		background: url("./img/bg1.jpg");
		margin: auto;
		max-height: 350px;
		max-width: 100%;
		min-height: 350px;
		min-width: 100%;
		display: flex;
		border-radius: 10px;
		box-shadow: 3px 5px 6px rgb(172, 168, 168);
		.cardtop {
			margin: auto;
			max-width: 210px;
			max-height: 210px;
			min-width: 210px;
			min-height: 210px;
			margin-top: 1.2%;
			border: 5px solid #ededed;
			border-radius: 60%;
			display: flex;
			overflow: hidden;
			background-color: #fff;
			justify-content: center;
			align-items: center;
			align-content: center;
			justify-items: center;
			img {
				flex: 1;
				margin: auto;
				max-width: 100%;
				max-height: 100%;
				min-width: 100%;
				min-height: 100%;
			}
		}
	}
	.forDescprofile {
		height: 20px;
		background-color: #ededed;
		margin: auto;
		width: 80%;
		display: flex;
		margin-top: -98px;
		margin-bottom: 30px;
		border-radius: 8px;
		box-shadow: 3px 5px 20px -5px rgba(10, 9, 9, 0.3);
		text-align: center;
		flex: 1;
		flex-direction: column;
		.userName {
			flex: 2;

			h1 {
				margin: auto;
				font-weight: 100;
				font-size: 35px;
				padding: 15px;
				padding-bottom: 0;
			}
		}
		.userPosition {
			flex: 1;
			display: flex;
			justify-content: center;
			align-items: center;
			h2 {
				font-weight: 200;
				font-size: 20px;
				padding-left: 15px;
				padding-right: 15px;
				margin-bottom: 0;
				display: flex;
				// background-color: red;
				height: 100%;
			}
			label {
				margin-bottom: 0;
				height: 100%;
				display: flex;
				align-items: center;
			}
		}

		.skills {
			flex: 1;
			max-width: 80%;
			min-width: 80%;
			margin: auto;
			display: flex;
			justify-content: center;
			font-weight: 100;
			font-size: 16px;
			margin-bottom: 10px;
			label {
				padding-left: 7px;
				padding-right: 7px;
			}
		}

		.userUserType {
			flex: 1;
			h2 {
				margin: auto;
				font-weight: 200;
				font-size: 22px;
				padding-bottom: 15px;
			}
		}
		.active {
			flex: 1;
			h2 {
				margin: auto;
				font-weight: 200;
				font-size: 22px;
				padding-bottom: 15px;
			}
		}

		.bio {
			flex: 5;
			h3 {
				padding-bottom: 20px;
				margin: auto;
				width: 80%;
				font-weight: 100;
				font-size: 20px;
			}
		}

		.homeAddress {
			flex: 1;
			background: rgba(0, 0, 0, 0.6);
			border-bottom-left-radius: 8px;
			border-bottom-right-radius: 8px;
			color: white;
			display: flex;
			.mapIcon {
				flex: 1;
			}
			svg {
				margin-bottom: -1%;
				height: 20px;
			}
			p {
				font-weight: normal;
				font-size: 18px;
			}
			.callIcon {
				flex: 1;
			}
			svg {
				margin-top: 5px;
			}
			p {
				font-weight: normal;
				font-size: 18px;
			}
			.emailIcon {
				flex: 1;
			}
			svg {
				margin-top: 5px;
			}
			p {
				font-weight: normal;
				font-size: 18px;
			}
		}
	}
}

.popUpWindows, .popUpWindow {
	section {
		display: flex;
		flex-direction: column;
		background-color: #fff;
		height: min-content;
		max-width: 98vw;
		border-top-left-radius: 5px;
		border-bottom-left-radius: 5px;
		border-bottom-right-radius: 5px;
		border-top-right-radius: 5px;
		.updateFormHeader {
			display: flex;
			align-items: center;
			justify-content: flex-end;
			flex: 1;
			border-top-left-radius: 5px;
			border-bottom-left-radius: 5px;
			border-top-right-radius: 5px;
			p {
				border-top-left-radius: 5px;
				border-top-right-radius: 5px;
				flex: 1;
				background-color: #333;
				margin: 0px;
				color: white;
				height: 100%;
				display: flex;
				align-items: center;
				justify-content: flex-end;
				padding: 1%;
			}
			.CloseCus {
				border-radius: 50%;
				width: 25px;
				height: 25px;
				margin-left: 9px;
				color: #00dd21;
				background-color: #ededed;
				border: none;
				cursor: pointer;
				font-size: 15px;
				font-weight: 800;
				transition: 0.3s;

				&:hover {
					transition: 0.3s;
					color: red;
				}
			}
		}

		.updateFormContent {
			display: flex;
			flex-wrap: wrap;

			flex-direction: row;
			flex: 13;
			margin-top: 0;
			top: 0;
			.updateformContainer {
				flex: 2;
				width: 500px;
				background-color: #fff;
				display: flex;
				flex-direction: column;
				place-items: center;

				input {
					display: block;
					
					height: 40px;
					width: 300px;
					font-size: 16px;
					border-style: none;
					border-bottom-color: #ededed;
					border-bottom-style: solid;
					border-bottom-width: thin;
					text-align: center;
					transition: 0.3s;
					background-color: transparent;
					margin-top: 0;
					padding: 0;
					margin: 0;
					padding-top: 5px;

					&:focus {
						outline: none;
						-webkit-transition: all 0.3s ease-in-out;
						-moz-transition: all 0.3s ease-in-out;
						-ms-transition: all 0.3s ease-in-out;
						-o-transition: all 0.3s ease-in-out;
						border-bottom-color: #00ff21;
						border-bottom-style: solid;
						border-bottom-width: thin;
						box-shadow: 0px 6px 10px -8px #00dd21;
					}
				}

				button.CustomerCancel {
					border-radius: 25px;
					border-style: solid;
					border-width: 0px;
					background-color: transparent;
					transition: 0.3s;
					background-color: #444;
					color: #fff;
					height: 50px;
					width: 200px;
					transition: 0.3s;
					margin-bottom: 5%;

					&:hover {
						background-color: #dd0404;
						transition: 0.3s;
						box-shadow: 1px 2px 5px #000;
						border-width: 0px;
						width: 210px;
						font-size: 15px;
						transition: 0.3s;
					}
				}
			}
			.skillMatrix {
				font-size: 15px;
				display: flex;
				text-align: center;
				position: relative;right:-150px;
				border-style: none;
					border-bottom-color: #ededed;
					border-bottom-style: solid;
					border-bottom-width: thin;
			}
			.updateFormProfile {
				flex: 4;
				display: flex;
				text-align: center;
				flex-direction: row;
				justify-items: center;
				align-items: center;
				align-content: center;

				section {
					flex: 1;
					// margin: 0;
					// max-height: 500px;
					height: 100%;
					display: flex;
					// background-color: red;
					flex-direction: column;
					margin-right: 4%;
					align-items: flex-start;
					
					textarea {
						// position: fixed;
						flex: 1;
						// display: block;
						height: 60%;
						max-height: 75%;
						font-size: 13px;
						font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell,
							"Open Sans", "Helvetica Neue", sans-serif;
						width: 60%;
						font-size: 15px;
						border-style: none;
						border-color: #ededed;
						border-style: solid;
						border-width: thin;
						// text-align: center;
						transition: 0.3s;
						resize: none;

					}
					
					.contactBio {
						margin: 10px;
						max-height: 200px;
						max-width: 80%;
						margin: auto;
						border: 1px solid #ccc;
					}
					.skillContainer {
						flex: 1;
						// background-color: red;
						display: flex;
						max-width: 450px;
						min-width: 450px;
					}
				}
				.sectionUpdateContact {
					flex: 1;
					// margin: 0;
					// max-height: 500px;
					height: 100%;
					margin: auto;
					display: flex;
					// background-color: red;
					flex-direction: column;
					// margin-right:4%;
					textarea {
						flex: 1;
						// display: block;
						font-size: 13px;
						font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell,
							"Open Sans", "Helvetica Neue", sans-serif;
						width: 100%;
						font-size: 15px;
						border-style: none;
						border-color: #ededed;
						border-style: solid;
						border-width: thin;
						// text-align: center;
						transition: 0.3s;
						// background-color: red;
						height: 50%;
						max-height: 50%;
						max-width: 90%;
						resize: none;
						margin-top: 1%;
					} 
				}
				.rightside {
					display: flex;
					flex: 1;
					flex-direction: column;
					padding-left: 20px;
					padding-right: 20px;
					max-height: 100%;
					border-left: 1px solid #ccc;
					input {
						// flex:1;
						position: relative;
						display: block;
						min-height: 40px;
						width: 300px;
						font-size: 16px;
						border-style: none;
						border-bottom-color: #ededed;
						border-bottom-style: solid;
						border-bottom-width: thin;
						text-align: center;
						transition: 0.3s;
						background-color: transparent;
						padding: 0;
						margin: auto;
						margin-top: 0;
						padding-top: 5px;

						&:focus {
							outline: none;
							-webkit-transition: all 0.3s ease-in-out;
							-moz-transition: all 0.3s ease-in-out;
							-ms-transition: all 0.3s ease-in-out;
							-o-transition: all 0.3s ease-in-out;
							border-bottom-color: #00ff21;
							border-bottom-style: solid;
							border-bottom-width: thin;
							box-shadow: 0px 6px 10px -8px #00dd21;
						}
					}
						textarea{
						
						text-align: center;
						
						flex: 1;
						// display: block;
						min-height: 150px;
						font-size: 13px;
						font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell,
							"Open Sans", "Helvetica Neue", sans-serif;
						font-size: 15px;
						border-style: none;
						border-color: #ededed;
						border-style: solid;
						border-width: thin;
						// text-align: center;
						transition: 0.3s;
						resize: none;

					}
					
					.UploadImage {
						flex: 1;
						margin: auto;
						padding: 0;
						min-height: 250px;
						max-height: 250px;
						min-width: 250px;
						max-width: 250px;
						display: flex;
						align-items: center;
						// background-color: red;
						.logo {
							// flex: 1;
							display: flex;
							min-height: 250px;
							max-height: 250px;
							min-width: 250px;
							max-width: 250px;
							// border: 2px solid #aaa;
							img {
								min-height: 150px;
								max-height: 150px;
								min-width: 150px;
								max-width: 150px;
								border-radius: 50%;
								border: 3px solid #fff;
							}
							.remove {
								img {
									min-width: 35px;
									max-width: 35px;
									min-height: 35px;
									max-height: 35px;
									cursor: pointer;
									border-radius: 0;
								}
							}
						}
					}
				}
			}
		}

		.SubmitContainer {
			flex: 1;
			display: flex;
			justify-content: left;
			border-bottom-left-radius: 10px;
			button.CustomerSubmit {
				border-radius: 25px;
				border-style: solid;
				border-width: 0px;
				background-color: transparent;
				transition: 0.3s;
				background-color: #00dd21;
				height: 50px;
				width: 200px;
				transition: 0.3s;
				margin-bottom: 2%;
				margin-top: 2%;
				margin-left:116px;
				cursor: pointer;

				&:hover {
					background-color: #00dd21;
					transition: 0.3s;
					box-shadow: 1px 2px 5px #000;
					border-width: 0px;
					width: 210px;
					font-size: 15px;
					transition: 0.3s;
				}

				
			}
		}
	}
}
