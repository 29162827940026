.AddCustomerForm {
  background-color: rgba(42, 42, 42, 0.8);
  width: 100%;
  height: 100%;
  z-index: 10;
  position: fixed;
  display: flex;
  justify-content: space-around;
  align-items: center;
  transition: 0.3s;
  overflow-y: hidden;
  top: 0;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  section {
    display: flex;
    flex-direction: column;
    background-color: #fff;
    height: min-content;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
    border-bottom-left-radius: 5px;
    .FormHeader {
      border-top-left-radius: 5px;
      border-top-right-radius: 5px;
      display: flex;
      align-items: center;
      justify-content: flex-end;
      flex: 1;
      p {
        border-top-left-radius: 5px;
        border-top-right-radius: 5px;
        flex: 1;
        background-color: #333;
        margin: 0px;
        color: white;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: flex-end;
        padding: 1%;
      }
      .CloseCus {
        border-radius: 50%;
        width: 25px;
        height: 25px;
        margin-left: 9px;
        color: #00dd21;
        background-color: #ededed;
        border: none;
        cursor: pointer;
        font-size: 15px;
        font-weight: 800;
        transition: 0.3s;

        &:hover {
          transition: 0.3s;
          color: red;
        }
      }
    }

    .FormContent {
      display: flex;
      flex-direction: row;
      flex: 13;
      margin-top: 0;
      top: 0;
      form {
        flex: 2;
        width: 500px;
        background-color: #fff;
        display: flex;
        flex-direction: column;
        place-items: center;

        input {
          display: block;
          height: 40px;
          width: 300px;
          font-size: 16px;
          border-style: none;
          border-bottom-color: #ededed;
          border-bottom-style: solid;
          border-bottom-width: thin;
          text-align: center;
          transition: 0.3s;
          background-color: transparent;
          margin-top: 0;
          padding: 0;
          margin: 0;
          padding-top: 5px;

          &:focus {
            outline: none;
            -webkit-transition: all 0.3s ease-in-out;
            -moz-transition: all 0.3s ease-in-out;
            -ms-transition: all 0.3s ease-in-out;
            -o-transition: all 0.3s ease-in-out;
            border-bottom-color: #00ff21;
            border-bottom-style: solid;
            border-bottom-width: thin;
            box-shadow: 0px 6px 10px -8px #00dd21;
          }
        }

        .userTypeOptions {
          display: flex;
          flex-wrap: wrap;
          justify-content: space-evenly;

          $colors: (
            b1: black,
          );

          @each $button, $color in $colors {
            .#{$button} {
              --color: #{$color};
              --hover: #{adjust-hue($color, 0deg)};
            }
          }

          button {
            color: var(--color);
            transition: 1.5s;
            cursor: pointer;

            &:hover,
            &:focus {
              border-color: var(--hover);
              color: white;
            }
          }

          .b1 {
            flex: 1;
            margin-top: 2px;
            margin-left: 2%;
            height: 40px;
            width: 150px;
            border-style: none;
            background-color: #eee;
            color: black;
            font-size: 16px;
            transition: 0.5s;
            &:hover,
            &:focus {
              box-shadow: inset 0 0 0 4em var(--hover);
            }
          }
        }

        button.CustomerCancel {
          border-radius: 25px;
          border-style: solid;
          border-width: 0px;
          background-color: transparent;
          transition: 0.3s;
          background-color: #444;
          color: #fff;
          height: 50px;
          width: 200px;
          transition: 0.3s;
          margin-bottom: 5%;
          cursor: pointer;

          &:hover {
            background-color: #dd0404;
            transition: 0.3s;
            box-shadow: 1px 2px 5px #000;
            border-width: 0px;
            width: 210px;
            font-size: 15px;
            transition: 0.3s;
          }
        }
      }

      .AddFormProfile {
        flex: 2;
        min-width: 1%;
        display: flex;
        text-align: center;
        flex-direction: column;
        justify-items: center;
        align-items: center;
        align-content: center;
        section {
          flex: 1;
          min-width: 100%;
          margin: 0;
          max-height: 130px;
          place-items: center;
          display: flex;
          flex-direction: column;
          input {
            display: block;
            height: 55px;
            width: 300px;
            font-size: 16px;
            border-style: none;
            border-bottom-color: #ededed;
            border-bottom-style: solid;
            border-bottom-width: thin;
            text-align: center;
            transition: 0.3s;
            background-color: transparent;
            margin-top: 0;
            padding: 0;
            margin: 0;
            padding-top: 5px;

            &:focus {
              outline: none;
              -webkit-transition: all 0.3s ease-in-out;
              -moz-transition: all 0.3s ease-in-out;
              -ms-transition: all 0.3s ease-in-out;
              -o-transition: all 0.3s ease-in-out;
              border-bottom-color: #00ff21;
              border-bottom-style: solid;
              border-bottom-width: thin;
              box-shadow: 0px 6px 10px -8px #00dd21;
            }
          }
          textarea {
            width: 85%;
            height: 150px;
            padding: 12px 20px;
            box-sizing: border-box;
            border: 2px solid #ccc;
            border-radius: 4px;
            background-color: #f8f8f8;
            resize: none;
            // text-align: inherit;
          }
        }
        .UploadImage {
          flex: 1;
          margin: 0;
          padding: 0;
          min-height: 250px;
          min-width: 250px;
          max-height: 250px;
          max-width: 250px;
          display: flex;
          .logo {
            flex: 1;
            display: flex;
            img {
              height: 100%;
              width: 100%;
              border-radius: 50%;
            }
            .remove {
              flex: 1;
              position: absolute;
              display: flex;
              img {
                width: 35px;
                height: 35px;
                cursor: pointer;
                border-radius: 0;
              }
            }
          }
        }
      }
    }

    .SubmitContainer {
      flex: 1;
      display: flex;
      justify-content: space-around;
      border-bottom-right-radius: 5px;
      border-bottom-left-radius: 5px;
      button.CustomerSubmit {
        border-radius: 25px;
        border-style: solid;
        border-width: 0px;
        background-color: transparent;
        transition: 0.3s;
        background-color: #00dd21;
        height: 50px;
        width: 200px;
        transition: 0.3s;
        margin-bottom: 2%;
        margin-top: 2%;
        cursor: pointer;

        &:hover {
          background-color: #00dd21;
          transition: 0.3s;
          box-shadow: 1px 2px 5px #000;
          border-width: 0px;
          width: 210px;
          font-size: 15px;
          transition: 0.3s;
        }
      }
    }
  }
}

.headings {
  flex: 1;
  margin-left: 6%;
}

.lines {
  width: 88%;
  flex: 1;
}

.columnContainer {
  background-color: transparent;
  height: auto;

  overflow-y: auto;
  // margin-left: 6.5%;
  margin: auto;
  width: 83%;
  overflow: hidden;
  .cardsCompany {
    display: flex;
    flex: 1;
    min-width: 100%;
    flex-wrap: wrap;
    min-height: 100%;
    justify-content: center;
    .aaa {
      // min-width: 200px;
      // max-width: 200px;
      // min-height: 200px;
      // max-height: 200px;
      height: 300px;
      width: 200px;
      // padding: 10px;
      // margin-bottom: 30px;
      display: flex;
      flex: 1;
      .companyCard {
        display: flex;
        flex: 1;
        margin: 0;
        padding: 0;
        // max-height: 70%;
        // background-color: red;

        .companyMiniCard {
          display: flex;
          flex: 1;
          flex-direction: column;
          min-height: 70%;
          min-width: 100%;
          margin: 0;
          flex: 1;
          .companyImage {
            flex: 3;
            display: flex;
            // height: 160px;
            width: 100%;
            height: 100%;
            // margin:auto;
            // margin-bottom:10px;
            justify-content: center;
            align-items: center;
            align-content: center;
            justify-items: center;
            background-color: #eee;
            border: none;
            .theImage {
              display: flex;
              flex: 1;
              max-width: 60%;
              max-height: 55%;
              min-width: 60%;
              min-height: 55%;
              background-color: #fff;

              img {
                flex: 1;
                border: none;
              }
            }
          }
          .customernames {
            flex: 1;
            display: flex;
            background-color: rgba(0, 0, 0, 0.8);
            justify-content: center;
            align-items: center;
            align-content: center;
            justify-items: center;
            color: #fff;
            text-align: center;
            // min-width:100%;
            // min-height: 100%;
          }
        }
      }
    }
  }

  .clickedcompanycard {
    background-color: transparent;
    max-width: 100%;
    min-width: 82vw;
    overflow: hidden;
    // background-color: blue;
    margin-top: 10px;
    transition: 0.3s;
    // height: 200px;
    transition: 0.2s;
    box-shadow: 0px 10px 55px -13px #333;
    -webkit-transform: translateY(-50%);
    transform: translateY(-3%);
    opacity: 1;
    // -webkit-transition-delay: 0.1s;
    // transition-delay: 0.1s;
    // background: rgba(255, 255, 255, 0.8);
    color: black;
    .QuickEdit {
      display: flex;
    }
    .quickeditmenu {
      // background-color: red;
      width: 5%;
      height: 100%;
      position: fixed;
      right: 1%;
      display: flex;
      flex-direction: column;
      justify-content: space-evenly;
      align-items: center;
      button{
        background-color: transparent;
        border-radius: 50%;
        height:50px;
        width:50px;
        svg{
          margin: auto;
          height:67%;
          width:67%;
          color:#0f2;
        }
      }
    }
  }

  .companyCardContainer {
    background-color: transparent;
    max-width: 100%;
    min-width: 82vw;
    overflow: hidden;
    // background-color: blue;
    margin-top: 10px;
    transition: 0.3s;
    // height: 200px;
    &:hover {
      transition: 0.2s;
      // box-shadow: 0px 5px 15px -12px #333;
      -webkit-transform: translateY(-50%);
      transform: translateY(-3%);
      opacity: 1;
      // -webkit-transition-delay: 0.1s;
      // transition-delay: 0.1s;
      // background: rgba(255, 255, 255, 0.8);
      color: black;
      z-index: 5;
      .QuickEdit {
        display: flex;
      }
    }
    .QuickEdit {
      background-color: transparent;
      height: 30px;
      // width: 100%;
      position: absolute;
      // background-color: red;
      display: none;
      z-index: 4;
      // margin-bottom: -10%;
      // justify-content: flex-end;
      justify-self: flex-end;
      right: 1%;
      transition: 1;
      cursor: pointer;
      svg {
        margin: auto;
        height: 20px;
        width: 20px;
        padding: 5px;
        // background-color: white;
      }
    }
  }
  .companyImage {
    width: 20px;
    height: 220px;
  }
}

.pageContainer {
  overflow-y: visible;
  width: 100%;
}
