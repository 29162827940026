.StatsContainer {
    height: 100%;
    width: 100%;
    background: rgba(0, 0, 0, 0.17);
    z-index: 10;
    position: fixed;
    top: 0;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    .StatsProfile {
        .closeStats {
            height: 40px;
            width: 40px;
            position: fixed;
            align-self: flex-end;
            font-size: 25px;
            // font-weight: 200;
            color: #888;
            background-color: transparent;
            border: none;
        }
        height: 90%;
        width: 90%;
        background-color: rgba(255, 255, 255, 0.98);
        box-shadow: 0px 0px 50px -20px #000;
        color: #444;
        border-radius: 20px;
        display: flex;
        flex-direction: column;
        padding: 10px;
        .top {
            background-color: transparent;
            flex: 4;
            display: flex;
            flex-wrap: wrap;
            flex-direction: row;
            padding-top: 20px;
            .left {
                flex: 1;
                display: flex;
                .customersList {
                    flex: 1;
                    display: flex;
                    flex-direction: column;
                    .customerHeaders {
                        display: flex;
                        flex-direction: row;
                        flex: 1;
                        color: #222;

                        div {
                            align-items: center;
                            justify-content: center;
                            display: flex;
                        }
                    }
                    .customersListContainer {
                        flex: 8;
                        display: flex;
                        flex-direction: column;
                        .customerItem {
                            display: flex;
                            flex-direction: row;
                            border-bottom: 1px solid #aaa;
                            background-color: rgba(0, 0, 0, 0.02);
                            height: 70px;
                            div {
                                align-items: center;
                                justify-content: center;
                                display: flex;
                            }
                        }
                    }
                    .customerInfo {
                        flex: 2;
                        .customerLogo {
                            flex: 1;
                            img {
                                height: 100%;
                                width: 100%;
                            }
                        }
                        .customerName {
                            flex: 4;
                        }
                    }
                    .Billable {
                        flex: 2;
                    }
                    .nonBillable {
                        flex: 2;
                    }
                    .Overtime {
                        flex: 2;
                    }
                    .Total {
                        flex: 1;
                    }
                }
            }
            .right {
                flex: 1;
                display: flex;
                border-left: 1px solid #bababa;
                .statsprofile {
                    display: flex;
                    flex: 1;
                    .left {
                        flex: 1;
                        display: flex;
                        flex-direction: column;
                        .hours {
                            display: flex;
                            flex: 12;
                            flex-direction: column;
                            .totalHourshours {
                                flex: 1;
                                display: flex;
                                align-items: center;
                                flex-direction: column;
                                justify-content: center;
                            }
                            .HoursSection {
                                display: flex;
                                flex: 5;
                                .left {
                                    text-indent: 40%;
                                    display: flex;
                                    flex-direction: column;
                                }
                                .right {
                                    text-indent: 20%;
                                    border-left: none;
                                    display: flex;
                                    flex-direction: column;
                                }
                                #chart {
                                    flex: 1;
                                    max-height: 200px;
                                    width: 100%;
                                    margin: 0;
                                }
                            }
                            .total {
                                margin-top: 10px;
                                color: black;
                                font-weight: 500;
                            }
                        }
                        .recent {
                            display: flex;
                            flex: 20;
                            background-color: blue;
                        }
                    }
                    .right {
                        flex: 1;
                        display: flex;
                        .statsuserprofile {
                            display: flex;
                            flex: 1;
                            flex-direction: column;
                            .top {
                                flex: 3;
                                display: flex;
                                flex-direction: column;
                                .imgContainer {
                                    flex: 2;
                                    display: flex;
                                    justify-content: center;
                                    align-items: center;
                                    img {
                                        width: 30%;
                                        height: auto;
                                        border-radius: 50%;
                                    }
                                }
                                .userDetailsstats {
                                    flex: 1;
                                    display: flex;
                                    flex-direction: column;
                                    align-items: center;
                                }
                            }
                            .bottom {
                                flex: 1;
                                display: flex;
                                flex-direction: column;
                                align-items: center;
                                p {
                                    margin: 0;
                                    padding: 0;
                                    margin-top: 7px;
                                }
                            }
                        }
                    }
                }
            }
        }
        #bottom {
            flex: 6;
            display: flex;
            overflow: hidden;
            border-top: 1px solid #aaa;
            .StatsEntryContainer {
                display: flex;
                flex-direction: column;
                flex: 1;
                max-height: 100%;
                overflow-y: scroll;
                // padding-top: 10px;
                .statentry {
                    // background-color: red;
                    min-height: 40px;
                    max-height: 100px;
                    display: flex;
                    flex-direction: row;
                    border-bottom: 1px solid #ccc;
                    padding-top: 10px;
                    div {
                        flex: 1;
                        display: flex;
                        flex-direction: column;
                        justify-content: start;
                        align-items: center;
                    }
                    .date {
                        flex: 0.8;
                    }
                    .time {
                        flex: 1;
                    }
                    .service {
                        display: flex;
                        label {
                            flex: 1;
                            font-weight: 300;
                            word-break: break-all;
                            // white-space: wrap;
                            // text-overflow: ellipsis;
                            overflow: hidden;
                        }
                        .dataHead {
                            font-weight: 400;
                        }
                    }
                    .description {
                        flex: 3;
                        // max-height: 50px;
                        // overflow: hidden;
                        // word-break: keep-all;
                        overflow: hidden;
                        display: flex;
                        align-items: flex-start;
                        // background-color: red;
                    }
                }
                #HeaderstatEntry {
                    padding: 0;
                    border-bottom: 1px solid #888;
                    div {
                        justify-content: center;
                        font-size: 18px;
                        color: #222;
                        font-size: 19px;
                    }
                }
            }

            .HoursGraphContainer{
                flex:1;
                display: flex;
                flex-direction: row;
                div{
                    flex:1;
                }
            }
        }
    }
}
