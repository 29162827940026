.calContainer {
  height: 100%;
  width: 100%;
  background: rgba(0, 0, 0, 0.3);
  position: fixed;
  z-index: 20;
  top: 0;
  display: flex;
  justify-content: flex-end;
  .calendar {
    border-radius: 10px;
    border: 1px solid #ccc;
    margin-right: 1%;
    margin-top: 5%;
    height: 350px;
    width: 35%;
  }
  .cal {
    height:100%;
    width:100%;
    right: 0;
    display: flex;
    flex-direction: column;
    align-items: flex-end;

    .closeCal {
      min-width: 30px;
      height: 30px;
      border: 0px;
      border-radius: 50%;
      background-color: #fff;
      color: red;
      font-weight: 500;
      font-size: 15px;
      transition: 0.3s;
      margin-right: 1%;

      &:hover {
        box-shadow: -2px 5px 10px -3px #000;
        transition: 0.3s;
      }
    }
  }
}
